import { api } from '@/lib/api.ts'

export interface UpdateTransactionInput {
  title?: string
  kind?: 'income' | 'outcome'
  date?: string
  billing_date?: string | null
  value?: number
  effected?: boolean
  user_category_id: string
  user_credit_card_id?: string | null
  user_account_id: string | null
  description: string | null
  edit_mode: 'single' | 'all' | 'next'
  receipt_url?: string | null
  invoice_url?: string | null
}

export type UploadTransactionAttachments = Pick<
  UpdateTransactionInput,
  'receipt_url' | 'invoice_url'
>

export async function updateTransaction<T = UpdateTransactionInput>(
  id: string,
  payload: T,
) {
  await api.put(`/transactions/${id}`, payload)
}
