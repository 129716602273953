import { Coins, CreditCard, Home, Landmark, LayoutList } from 'lucide-react'
import { Tooltip } from './tooltip'
import { cn } from '@/lib/utils'
import { Link, useLocation } from 'react-router-dom'
import { useMemo } from 'react'

interface Item {
  title: string
  icon: JSX.Element
  href: string
  root?: boolean
}

const items: Item[] = [
  {
    href: '/',
    root: true,
    icon: <Home className="h-4 w-4 transition-all group-hover:scale-110" />,
    title: 'Página Inicial',
  },
  {
    href: '/transacoes',
    icon: <Coins className="h-4 w-4 transition-all group-hover:scale-110" />,
    title: 'Transacões',
  },
  {
    href: '/cartoes_credito',
    icon: (
      <CreditCard className="h-4 w-4 transition-all group-hover:scale-110" />
    ),
    title: 'Cartões de crédito',
  },
  {
    href: '/contas',
    icon: <Landmark className="h-4 w-4 transition-all group-hover:scale-110" />,
    title: 'Contas',
  },
  {
    href: '/categorias',
    icon: (
      <LayoutList className="h-4 w-4 transition-all group-hover:scale-110" />
    ),
    title: 'Categorias',
  },
]

function MenuItem({ item }: { item: Item }) {
  const location = useLocation()

  const isActive = useMemo(() => {
    if (location.pathname === '/' && item.root) return true

    return !item.root && location.pathname.includes(item.href)
  }, [item, location])

  return (
    <Tooltip key={item.href} title={item.title}>
      <Link
        to={{
          search: item.href === location.pathname ? location.search : '',
          pathname: item.href,
        }}
        className={cn(
          'group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full text-lg font-semibold md:h-8 md:w-8 md:text-base',
          {
            'bg-primary text-primary-foreground': isActive,
          },
        )}
      >
        {item.icon}
      </Link>
    </Tooltip>
  )
}

export function MenuBar() {
  return (
    <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
      <nav className="flex flex-col items-center gap-4 px-2 sm:py-5">
        {items.map((item) => (
          <MenuItem key={item.title} item={item} />
        ))}
      </nav>
    </aside>
  )
}
