import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ReactNode, useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { GET_TRANSACTIONS } from '@/lib/react-query/keys.ts'
import {
  BankTransferForm,
  IBankTransferFormType,
} from '@/pages/transactions/components/transfer-form-modal.tsx'
import { useGetAccounts } from '@/lib/react-query/requests/accounts/get-accounts.ts'
import { format } from 'date-fns'
import { TransactionsOutput } from '@/lib/react-query/requests/transactions/get-transactions.ts'
import {
  updateBankTransfer,
  UpdateBankTransferInput,
} from '@/lib/react-query/requests/bank_transfers/update-bank-transfer.ts'
import { Tooltip } from '@/components/tooltip.tsx'

const CLOSE_DIALOG_ELEMENT_ID = 'update-bank-transfer-dialog-id'

interface UpdateTransferModalProps {
  triggerElement: ReactNode
  transaction: TransactionsOutput['data'][0]
}

export const UpdateBankTransferModal = ({
  triggerElement,
  transaction,
}: UpdateTransferModalProps) => {
  const queryClient = useQueryClient()

  const { data } = useGetAccounts(1, 100)

  const closeModal = useCallback(() => {
    const el = document.getElementById(CLOSE_DIALOG_ELEMENT_ID)
    if (el) el.click()
  }, [])

  const { mutate: handleUpdateBankTransfer, isPending } = useMutation({
    mutationFn: (data: UpdateBankTransferInput) =>
      updateBankTransfer(transaction.bank_transfer?.id || '', data),
    onSuccess: async () => {
      toast.success('Transferência criada com sucesso')
      await queryClient.invalidateQueries({
        queryKey: [GET_TRANSACTIONS],
      })
      closeModal()
    },
  })

  const onSubmit = useCallback(
    (data: IBankTransferFormType) => {
      handleUpdateBankTransfer({
        value: data.value,
        date: format(data.date, 'yyyy-MM-dd'),
        user_account_to_id: data.user_account_to_id,
        user_account_from_id: data.user_account_from_id,
      })
    },
    [handleUpdateBankTransfer],
  )

  return (
    <Dialog>
      <Tooltip title={'Editar'}>
        <div>
          <DialogTrigger>{triggerElement}</DialogTrigger>
        </div>
      </Tooltip>
      <DialogContent
        className={'w-[400px]'}
        closeButtonElementId={CLOSE_DIALOG_ELEMENT_ID}
      >
        <DialogHeader>
          <DialogTitle>Atualizar Transferência</DialogTitle>
        </DialogHeader>
        <BankTransferForm
          accounts={data?.data || []}
          onSubmit={onSubmit}
          isLoading={isPending}
          initialData={transaction}
        />
      </DialogContent>
    </Dialog>
  )
}
