import { useQuery } from '@tanstack/react-query'
import { api } from '@/lib/api.ts'
import { GET_CREDIT_CARD_DATA } from '@/lib/react-query/keys.ts'

interface CreditCardsDataFilters {
  account_id?: string
  start_date?: string
  end_date?: string
}

export interface CreditCardsDataOutput {
  id: string
  credit_card_name: string
  total_value: number
  due_date: string
  account_name: string
  effected: boolean
  account_id: string
}

export const useGetCreditCardsData = (filters: CreditCardsDataFilters) => {
  return useQuery({
    queryKey: [GET_CREDIT_CARD_DATA, filters],
    enabled: !!(filters.start_date && filters.end_date),
    queryFn: async () => {
      const findByDate = filters.start_date && filters.end_date

      const { data } = await api.get<CreditCardsDataOutput[]>(
        `/transactions/credit_cards`,
        {
          params: {
            start_date: findByDate ? filters.start_date : undefined,
            end_date: findByDate ? filters.end_date : undefined,
            account_id: filters.account_id || undefined,
          },
        },
      )
      return data
    },
  })
}
