import { z } from 'zod'
import { Form } from '@/components/ui/form.tsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ButtonLoading } from '@/components/form/button-loading.tsx'
import { MoneyInput } from '@/components/form/money-input.tsx'
import { SelectField } from '@/components/form/select.tsx'
import { DatePickerForm } from '@/components/form/date-picker.tsx'
import { AccountsOutput } from '@/lib/react-query/requests/accounts/get-accounts.ts'
import { TransactionsOutput } from '@/lib/react-query/requests/transactions/get-transactions.ts'
import { parseISO } from 'date-fns'

const schema = z.object({
  value: z
    .number({ coerce: true })
    .positive({ message: 'O valor precisa ser um valor positivo.' }),
  user_account_from_id: z
    .string({ message: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  user_account_to_id: z
    .string({ message: 'Campo obrigatório' })
    .min(1, 'Campo obrigatório'),
  date: z.date({
    required_error: 'Campo obrigatório',
  }),
})

export type IBankTransferFormType = z.infer<typeof schema>

interface BankTransferFormProps {
  onSubmit: (data: IBankTransferFormType) => void
  isLoading: boolean
  accounts: AccountsOutput['data']
  initialData: TransactionsOutput['data'][0] | null
}

export const BankTransferForm = ({
  onSubmit,
  isLoading = false,
  accounts,
  initialData,
}: BankTransferFormProps) => {
  const form = useForm<IBankTransferFormType>({
    resolver: zodResolver(schema),
    defaultValues: {
      date: initialData?.date ? parseISO(initialData?.date) : new Date(),
      value: initialData?.value ?? 0,
      user_account_from_id: initialData?.bank_transfer?.account_from?.id ?? '',
      user_account_to_id: initialData?.bank_transfer?.account_to?.id ?? '',
    },
  })

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-6"
      >
        <MoneyInput<IBankTransferFormType>
          name={'value'}
          label={'Valor'}
          placeholder={'Valor'}
        />

        <SelectField<IBankTransferFormType>
          name={'user_account_from_id'}
          label={'Conta origem'}
          placeholder={'Selecione a conta origem'}
          options={accounts.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
        />

        <SelectField<IBankTransferFormType>
          name={'user_account_to_id'}
          label={'Conta destino'}
          placeholder={'Selecione a conta destino'}
          options={accounts.map((item) => ({
            label: item.name,
            value: item.id,
          }))}
        />

        <DatePickerForm<IBankTransferFormType>
          label="Data"
          name={`date`}
          placeholder="Data"
          calendarProps={{
            mode: 'single',
          }}
        />

        <ButtonLoading type={'submit'} isLoading={isLoading}>
          <span>Salvar</span>
        </ButtonLoading>
      </form>
    </Form>
  )
}
