import { CategoriesTable } from '@/pages/categories/components/table.tsx'
import { Button } from '@/components/ui/button'
import { Plus } from 'lucide-react'
import { CreateCategoryModal } from '@/pages/categories/components/create-category-modal.tsx'
import { Dialog, DialogTrigger } from '@/components/ui/dialog.tsx'

export function Categories() {
  return (
    <>
      <div className={'w-full max-w-[1200px] m-auto'}>
        <div className={'w-full mb-4 flex justify-between items-center'}>
          <span className={'text-lg block'}>Categorias</span>
          <Dialog>
            <DialogTrigger>
              <Button
                size={'icon'}
                variant={'default'}
                className={
                  'w-12 h-12 rounded-full flex items-center justify-center hover:bg-primary/70'
                }
              >
                <Plus />
              </Button>
            </DialogTrigger>
            <CreateCategoryModal />
          </Dialog>
        </div>
        <CategoriesTable />
      </div>
    </>
  )
}
