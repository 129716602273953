import { api } from '@/lib/api.ts'

export interface CreateBankTransferInput {
  value: number
  user_account_from_id: string
  user_account_to_id: string
  date: string
}

export const createBankTransfer = async (data: CreateBankTransferInput) => {
  await api.post('/bank_transfers', data)
}
