import { api } from '@/lib/api.ts'

export interface UpdateCreditCardInput {
  name: string
  limit: number
  closing_day: number
  due_day: number
  user_account_id: string
  color: string
}

export const updateCreditCard = async (
  id: string,
  data: UpdateCreditCardInput,
) => {
  await api.put(`/credit_cards/${id}`, data)
}
