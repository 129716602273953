import { Loader2 } from 'lucide-react'

import { Button, ButtonProps } from '@/components/ui/button'

interface ButtonLoadingProps extends ButtonProps {
  isLoading?: boolean
  loadingText?: string
}

export function ButtonLoading({
  isLoading = false,
  loadingText = '',
  ...rest
}: ButtonLoadingProps) {
  return (
    <Button {...rest} disabled={isLoading || rest.disabled}>
      {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
      {isLoading ? loadingText || rest.children : rest.children}
    </Button>
  )
}
