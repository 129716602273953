import { useGetValuesByKindByCategory } from '@/lib/react-query/requests/dashboard/get-values-by-categories-by-kind.ts'
import { useCallback, useMemo, useState } from 'react'
import { endOfMonth, format, parseISO, set, startOfMonth } from 'date-fns'
import { useGetCreditCards } from '@/lib/react-query/requests/credit_cards/get-credit-cards.ts'
import { useGetValuesByCreditCard } from '@/lib/react-query/requests/dashboard/get-values-by-credit-card.ts'
import { useGetTotalCreditCardsValues } from '@/lib/react-query/requests/dashboard/get-total-credit-cards-values.ts'
import { CardSection } from '@/pages/dashboard/components/cards-section.tsx'
import { ValuesByCategoryChart } from '@/pages/dashboard/components/charts/values-by-category.tsx'
import { ValuesByCreditCardChart } from '@/pages/dashboard/components/charts/values-by-credit-card.tsx'
import { DatePickerWithRange } from '@/components/ui/date-picker-range.tsx'
import { DateRange } from 'react-day-picker'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

export function Dashboard() {
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [dateRange, setDateRange] = useState<DateRange | undefined>(() => {
    const queryStartDate = searchParams.get('start_date')
    const queryEndDate = searchParams.get('end_date')

    const startDate = queryStartDate
      ? parseISO(queryStartDate)
      : startOfMonth(new Date())

    const endDate = queryEndDate
      ? parseISO(queryEndDate)
      : endOfMonth(new Date())

    return {
      from: set(startDate, {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      to: set(endDate, {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
    }
  })

  const onChangeDateRange = useCallback(
    (selectedDateRange: DateRange | undefined) => {
      if (!selectedDateRange) return

      setDateRange(selectedDateRange)

      if (selectedDateRange?.from && selectedDateRange.to) {
        const params = new URLSearchParams(searchParams.toString())
        params.set('start_date', format(selectedDateRange.from, 'yyyy-MM-dd'))
        params.set('end_date', format(selectedDateRange.to, 'yyyy-MM-dd'))
        params.delete('page')
        navigate(`${pathname}?${params.toString()}`)
      }
    },
    [navigate, pathname, searchParams],
  )

  const { data: creditCardsData } = useGetCreditCards(1, 100)

  const formattedDates = useMemo(() => {
    return {
      startDate: dateRange?.from ? format(dateRange.from, 'yyyy-MM-dd') : '',
      endDate: dateRange?.to ? format(dateRange.to, 'yyyy-MM-dd') : '',
    }
  }, [dateRange])

  const { data: incomeByCategoryData, isLoading: incomeByCategoryIsLoading } =
    useGetValuesByKindByCategory(
      'income',
      formattedDates.startDate,
      formattedDates.endDate,
    )
  const { data: outcomeByCategoryData, isLoading: outcomeByCategoryIsLoading } =
    useGetValuesByKindByCategory(
      'outcome',
      formattedDates.startDate,
      formattedDates.endDate,
    )

  const { data: creditCardsValuesData, isLoading: creditCardsValuesIsLoading } =
    useGetValuesByCreditCard(formattedDates.startDate, formattedDates.endDate)

  const {
    data: creditCardsTotalValuesData,
    isLoading: creditCardsTotalValuesIsLoading,
  } = useGetTotalCreditCardsValues(
    formattedDates.startDate,
    formattedDates.endDate,
  )

  return (
    <div className={'w-full max-w-[1200px] flex flex-col m-auto gap-4'}>
      <DatePickerWithRange date={dateRange} setDate={onChangeDateRange} />

      <CardSection
        isLoading={creditCardsTotalValuesIsLoading}
        values={{
          creditCardsTotalAmount:
            creditCardsTotalValuesData?.credit_cards_total_amount || 0,
          totalBalance: creditCardsTotalValuesData?.total_balance || 0,
          monthlyIncome: creditCardsTotalValuesData?.monthly_income || 0,
          monthlyOutcome: creditCardsTotalValuesData?.monthly_outcome || 0,
        }}
      />

      <div className={'flex gap-4'}>
        <ValuesByCategoryChart
          data={outcomeByCategoryData || []}
          title={'Despesas por categoria'}
          isLoading={outcomeByCategoryIsLoading}
        />
        <ValuesByCategoryChart
          data={incomeByCategoryData || []}
          title={'Receitas por categoria'}
          isLoading={incomeByCategoryIsLoading}
        />
      </div>

      <ValuesByCreditCardChart
        title={'Despesas mensais por cartão de crédito'}
        data={creditCardsValuesData || null}
        creditCards={creditCardsData?.data || []}
        isLoading={creditCardsValuesIsLoading}
      />
    </div>
  )
}
