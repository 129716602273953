import { AccountsList } from '@/pages/accounts/components/list.tsx'
import { Dialog, DialogTrigger } from '@/components/ui/dialog.tsx'
import { Button } from '@/components/ui/button.tsx'
import { Plus } from 'lucide-react'
import { CreateAccountModal } from '@/pages/accounts/components/create-account-modal.tsx'
import { useCallback, useMemo } from 'react'
import MonthPicker from '@/components/ui/month-picker.tsx'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

export function Accounts() {
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const date = useMemo(() => {
    const month = searchParams.get('month')
      ? Number(searchParams.get('month')) - 1
      : new Date().getMonth().toString()
    const year = searchParams.get('year') || new Date().getFullYear().toString()
    return new Date(Number(year), Number(month), 1)
  }, [searchParams])

  const changeDate = useCallback(
    (date: Date) => {
      const params = new URLSearchParams(searchParams.toString())
      params.set('month', Number(date.getMonth() + 1).toString())
      params.set('year', date.getFullYear().toString())
      navigate(`${pathname}?${params.toString()}`)
    },
    [navigate, pathname, searchParams],
  )

  return (
    <div className={'w-full max-w-[1200px] m-auto'}>
      <div className={'w-full mb-2 flex justify-between items-center'}>
        <div className={'flex flex-col'}>
          <span className={'text-lg block'}>Contas</span>
        </div>
        <Dialog>
          <DialogTrigger>
            <Button
              size={'icon'}
              variant={'default'}
              className={
                'w-12 h-12 rounded-full flex items-center justify-center hover:bg-primary/70'
              }
            >
              <Plus />
            </Button>
          </DialogTrigger>
          <CreateAccountModal />
        </Dialog>
      </div>

      <div className={'w-full mb-4'}>
        <MonthPicker date={date} onDateChange={changeDate} />
      </div>

      <AccountsList />
    </div>
  )
}
