import { api } from '@/lib/api.ts'

export interface ChangeTransactionsAccountInput {
  user_account_id: string
  month: number
  year: number
}

export const changeTransactionsAccount = async (
  id: string,
  data: ChangeTransactionsAccountInput,
) => {
  await api.patch(`/credit_cards/${id}/transactions_account`, data)
}
