import { api } from '@/lib/api.ts'

export interface CreateCreditCardInput {
  name: string
  limit: number
  closing_day: number
  due_day: number
  user_account_id: string
  color: string
}

export const createCreditCard = async (data: CreateCreditCardInput) => {
  await api.post('/credit_cards', data)
}
