import { api } from '@/lib/api.ts'

export const deleteTransaction = async (
  transactionId: string,
  mode = 'single',
) => {
  await api.delete(`/transactions/${transactionId}`, {
    params: {
      mode,
    },
  })
}
