import { api } from '@/lib/api.ts'

export interface PayCreditCardInput {
  month: number
  year: number
  user_account_id?: string
}

export const payCreditCard = async (id: string, data: PayCreditCardInput) => {
  await api.put(`/credit_cards/${id}/pay`, data)
}
