import { MenuBar } from '@/components/menu-bar'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { LogOut } from 'lucide-react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useGetMe } from '@/lib/react-query/requests/me/get-me.ts'

export function AuthLayout() {
  const navigate = useNavigate()
  const { data } = useGetMe()

  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40">
      <MenuBar />
      <div className="flex flex-col sm:gap-4 sm:py-2 sm:pl-[4.5rem] sm:pr-4">
        <div className="w-full h-10 flex items-center justify-end">
          <DropdownMenu>
            <DropdownMenuTrigger asChild className="cursor-pointer">
              <Avatar>
                <AvatarImage src="" />
                <AvatarFallback>DC</AvatarFallback>
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-48">
              <DropdownMenuLabel>{data?.name || ''}</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={() => {
                  navigate('/signout', { replace: true })
                }}
              >
                <LogOut className="mr-2 h-4 w-4" />
                <span>Sair</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <Outlet />
      </div>
    </div>
  )
}
