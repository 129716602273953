import { api } from '@/lib/api.ts'

export interface UpdateBankTransferInput {
  value: number
  user_account_from_id: string
  user_account_to_id: string
  date: string
}

export const updateBankTransfer = async (
  id: string,
  data: UpdateBankTransferInput,
) => {
  await api.put(`/bank_transfers/${id}`, data)
}
