import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table.tsx'
import { format, parseISO } from 'date-fns'
import { formatMoney } from '@/lib/format-money.ts'
import { LoadingContainer } from '@/components/loading-container.tsx'
import { Tooltip } from '@/components/tooltip.tsx'
import {
  Check,
  CircleAlert,
  CircleCheckBig,
  List,
  Pencil,
  X,
} from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { CreditCardsDataOutput } from '@/lib/react-query/requests/transactions/get-credit-cards-data.ts'
import { ChangeCreditCardAccountDialog } from '@/pages/transactions/components/change-credit-card-account.tsx'
import { PaymentDialog } from '@/components/dialogs/payment-dialog.tsx'

interface CreditCardsTableProps {
  items: CreditCardsDataOutput[]
  isLoading: boolean
}

export const CreditCardsTable = ({
  items,
  isLoading,
}: CreditCardsTableProps) => {
  const navigate = useNavigate()

  if (!items.length) {
    return (
      <div className="my-10 w-full">
        <span className="flex items-center justify-center text-xl">
          {isLoading ? <LoadingContainer /> : 'Não há dados para exibir'}
        </span>
      </div>
    )
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Situação</TableHead>
          <TableHead>Data</TableHead>
          <TableHead>Nome</TableHead>
          <TableHead>Conta</TableHead>
          <TableHead></TableHead>
          <TableHead>Valor</TableHead>
          <TableHead></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item) => (
          <TableRow className={'h-12'} key={item.id}>
            <TableCell>
              <div className={'flex items-center gap-2'}>
                {item.effected && (
                  <Tooltip title={'Pago'}>
                    <CircleCheckBig color={'green'} className={'h-5 w-5'} />
                  </Tooltip>
                )}
                {!item.effected && (
                  <Tooltip title={'Pendente'}>
                    <CircleAlert color={'red'} className={'h-5 w-5'} />
                  </Tooltip>
                )}
              </div>
            </TableCell>
            <TableCell>
              {format(parseISO(item.due_date), 'dd/MM/yyyy')}
            </TableCell>
            <TableCell>{item.credit_card_name}</TableCell>
            <TableCell>{item.account_name}</TableCell>
            <TableCell></TableCell>
            <TableCell className={'text-red-600'}>
              {formatMoney(item.total_value)}
            </TableCell>
            <TableCell>
              <div className={'flex gap-2 items-center justify-end'}>
                <PaymentDialog
                  userAccountId={item.account_id}
                  isPaid={item.effected || false}
                  triggerElement={
                    <Tooltip
                      title={
                        item.effected
                          ? 'Remover Pagamento'
                          : 'Efetuar Pagamento'
                      }
                    >
                      <button>
                        {item.effected ? <X size={16} /> : <Check size={16} />}
                      </button>
                    </Tooltip>
                  }
                  creditCard={{
                    id: item.id,
                    name: item.credit_card_name,
                  }}
                  month={new Date(item.due_date).getMonth() + 1}
                  year={new Date(item.due_date).getFullYear()}
                />

                <ChangeCreditCardAccountDialog
                  payload={{
                    credit_card_name: item.credit_card_name,
                    year: new Date(item.due_date).getFullYear(),
                    month: new Date(item.due_date).getMonth() + 1,
                    id: item.id,
                    user_account_id: item.account_id,
                  }}
                  triggerElement={
                    <Tooltip title={'Alterar Conta de Faturamento'}>
                      <button>
                        <Pencil size={16} />
                      </button>
                    </Tooltip>
                  }
                />

                <Tooltip title={'Acessar Fatura'}>
                  <button
                    onClick={() => {
                      const month = new Date(item.due_date).getMonth() + 1
                      const year = new Date(item.due_date).getFullYear()

                      navigate(
                        `/cartoes_credito/${item.id}/faturas?month=${month}&year=${year}`,
                      )
                    }}
                  >
                    <List size={16} />
                  </button>
                </Tooltip>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
