import { FieldValues, Path, useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { cn } from '@/lib/utils'
import { Textarea, TextareaProps } from '@/components/ui/textarea.tsx'

interface TextareaFieldProps<T extends FieldValues> {
  name: Path<T>
  label: string
  inputProps?: TextareaProps
  formClassName?: string
}

export function TextareaField<T extends FieldValues>({
  name,
  label,
  inputProps = {},
  formClassName,
}: TextareaFieldProps<T>) {
  const { control } = useFormContext<T>()

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn('w-full', formClassName)}>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea
              {...field}
              {...inputProps}
              onChange={(e) => field.onChange(e)}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
