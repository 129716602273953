import { z } from 'zod'
import { Form } from '@/components/ui/form.tsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { InputField } from '@/components/form/input.tsx'
import { ButtonLoading } from '@/components/form/button-loading.tsx'
import { CreditCardsOutput } from '@/lib/react-query/requests/credit_cards/get-credit-cards.ts'
import { MoneyInput } from '@/components/form/money-input.tsx'
import { SelectField } from '@/components/form/select.tsx'
import { useGetAccounts } from '@/lib/react-query/requests/accounts/get-accounts.ts'
import { useMemo } from 'react'
import { ICategoryFormType } from '@/pages/categories/components/category-form-modal.tsx'

const DEFAULT_COLOR = '#000'

const schema = z.object({
  name: z.string({ coerce: true }).min(1, 'Campo obrigatório').default(''),
  limit: z.number({ coerce: true }).min(1, 'Campo obrigatório').default(0),
  closing_day: z
    .string({ coerce: true })
    .min(1, 'Campo obrigatório')
    .default(''),
  due_day: z.string({ coerce: true }).min(1, 'Campo obrigatório').default(''),
  user_account_id: z
    .string({ coerce: true })
    .min(1, 'Campo obrigatório')
    .default(''),
  color: z.string({ coerce: true }).default(DEFAULT_COLOR),
})

export type ICreditCardFormType = z.infer<typeof schema>

interface CreditCardFormProps {
  onSubmit: (data: ICreditCardFormType) => void
  isLoading: boolean
  initialData: CreditCardsOutput['data'][0] | null
}

export const CreditCardForm = ({
  initialData,
  onSubmit,
  isLoading = false,
}: CreditCardFormProps) => {
  const { data: accountsData } = useGetAccounts(1, 100)

  const accounts = useMemo(() => {
    return accountsData?.data || []
  }, [accountsData?.data])

  const days = useMemo(() => {
    return Array.from({ length: 31 }, (_, i) => i + 1).map((item) => ({
      label: item.toString(),
      value: item.toString(),
    }))
  }, [])

  const form = useForm<ICreditCardFormType>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: initialData?.name ?? '',
      user_account_id: initialData?.account?.id ?? '',
      closing_day: String(initialData?.closing_day ?? ''),
      due_day: String(initialData?.due_day ?? ''),
      limit: initialData?.limit ?? 0,
    },
  })

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-6"
      >
        <InputField<ICreditCardFormType>
          name="name"
          label="Nome"
          inputProps={{
            placeholder: 'Nome',
          }}
        />

        <MoneyInput<ICreditCardFormType>
          name={'limit'}
          label={'Limite'}
          placeholder={'Limite'}
        />

        <SelectField<ICreditCardFormType>
          name={'closing_day'}
          label={'Data fechamento'}
          placeholder={'Data fechamento'}
          options={days}
        />

        <SelectField<ICreditCardFormType>
          name={'due_day'}
          label={'Data vencimento'}
          placeholder={'Data vencimento'}
          options={days}
        />

        <SelectField<ICreditCardFormType>
          name={'user_account_id'}
          label={'Conta padrão'}
          placeholder={'Conta'}
          options={accounts.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
        />

        <InputField<ICategoryFormType>
          name="color"
          label="Cor"
          inputProps={{
            type: 'color',
          }}
        />

        <ButtonLoading type={'submit'} isLoading={isLoading}>
          <span>Salvar</span>
        </ButtonLoading>
      </form>
    </Form>
  )
}
