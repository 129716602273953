import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Login } from '@/pages/login'
import { Signout } from '@/pages/signout'
import { AuthLayout } from '@/_layouts/auth'
import { Dashboard } from '@/pages/dashboard'
import { Categories } from '@/pages/categories'
import { Transactions } from '@/pages/transactions'
import { Accounts } from '@/pages/accounts'
import { CreditCards } from '@/pages/credit_cards'
import { PrivateRoute } from '@/private-route'
import { CreditCardInvoices } from '@/pages/credit-card-invoices.tsx'
import { Page } from '@/_layouts/page.tsx'
import { NotFoundPage } from '@/pages/not-found.tsx'

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={'/login'}
          element={
            <PrivateRoute>
              <Login />
            </PrivateRoute>
          }
        />
        <Route path={'/signout'} element={<Signout />} />

        <Route
          element={
            <PrivateRoute>
              <AuthLayout />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <Page title={'Dashboard'}>
                <Dashboard />
              </Page>
            }
          />
          <Route
            path={'/categorias'}
            element={
              <Page title={'Categorias'}>
                <Categories />
              </Page>
            }
          />
          <Route
            path={'/transacoes'}
            element={
              <Page title={'Transações'}>
                <Transactions />
              </Page>
            }
          />
          <Route
            path={'/contas'}
            element={
              <Page title={'Contas'}>
                <Accounts />
              </Page>
            }
          />
          <Route
            path={'/cartoes_credito'}
            element={
              <Page title={'Cartões de crédito'}>
                <CreditCards />
              </Page>
            }
          />
          <Route
            path={'/cartoes_credito/:id/faturas'}
            element={
              <Page title={'Faturas'}>
                <CreditCardInvoices />
              </Page>
            }
          />
        </Route>
        <Route path={'/*'} element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}
