import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ReactNode, useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { GET_CREDIT_CARDS } from '@/lib/react-query/keys.ts'
import {
  CreditCardForm,
  ICreditCardFormType,
} from '@/pages/credit_cards/components/credit-card-form.tsx'
import {
  updateCreditCard,
  UpdateCreditCardInput,
} from '@/lib/react-query/requests/credit_cards/update-credit-card.ts'
import { CreditCardsOutput } from '@/lib/react-query/requests/credit_cards/get-credit-cards.ts'

const CLOSE_DIALOG_ELEMENT_ID = 'update-credit-card-dialog-id'

interface UpdateCreditCardModalProps {
  triggerElement: ReactNode
  creditCard: CreditCardsOutput['data'][0]
}

export const UpdateCreditCardModal = ({
  triggerElement,
  creditCard,
}: UpdateCreditCardModalProps) => {
  const queryClient = useQueryClient()

  const closeModal = useCallback(() => {
    const el = document.getElementById(CLOSE_DIALOG_ELEMENT_ID)
    if (el) el.click()
  }, [])

  const { mutate: handleUpdateCreditCard, isPending } = useMutation({
    mutationFn: (data: UpdateCreditCardInput) =>
      updateCreditCard(creditCard.id, data),
    onSuccess: async () => {
      toast.success('Cartão de crédito atualizado com sucesso')
      await queryClient.invalidateQueries({
        queryKey: [GET_CREDIT_CARDS],
      })
      closeModal()
    },
  })

  const onSubmit = useCallback(
    (data: ICreditCardFormType) => {
      handleUpdateCreditCard({
        name: data.name,
        due_day: Number(data.due_day),
        closing_day: Number(data.closing_day),
        color: data.color,
        user_account_id: data.user_account_id,
        limit: data.limit,
      })
    },
    [handleUpdateCreditCard],
  )

  return (
    <Dialog>
      <DialogTrigger
        className={
          'w-full relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-accent focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
        }
      >
        {triggerElement}
      </DialogTrigger>
      <DialogContent
        className={'w-[400px]'}
        closeButtonElementId={CLOSE_DIALOG_ELEMENT_ID}
      >
        <DialogHeader>
          <DialogTitle>Editar cartão de crédito</DialogTitle>
        </DialogHeader>
        <CreditCardForm
          initialData={creditCard}
          onSubmit={onSubmit}
          isLoading={isPending}
        />
      </DialogContent>
    </Dialog>
  )
}
