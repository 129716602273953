import {
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { GET_ACCOUNTS } from '@/lib/react-query/keys.ts'
import {
  createAccount,
  CreateAccountInput,
} from '@/lib/react-query/requests/accounts/create-account.ts'
import {
  AccountFormModal,
  IAccountFormType,
} from '@/pages/accounts/components/account-form-modal.tsx'

const CLOSE_DIALOG_ELEMENT_ID = 'create-account-dialog-id'

export const CreateAccountModal = () => {
  const queryClient = useQueryClient()

  const closeModal = useCallback(() => {
    const el = document.getElementById(CLOSE_DIALOG_ELEMENT_ID)
    if (el) el.click()
  }, [])

  const { mutate: handleCreateAccount, isPending } = useMutation({
    mutationFn: (data: CreateAccountInput) => createAccount(data),
    onSuccess: async () => {
      toast.success('Conta criada com sucesso')
      await queryClient.invalidateQueries({
        queryKey: [GET_ACCOUNTS],
      })
      closeModal()
    },
  })

  const onSubmit = useCallback(
    (data: IAccountFormType) => {
      handleCreateAccount(data)
    },
    [handleCreateAccount],
  )

  return (
    <DialogContent closeButtonElementId={CLOSE_DIALOG_ELEMENT_ID}>
      <DialogHeader>
        <DialogTitle>Nova Conta</DialogTitle>
      </DialogHeader>
      <AccountFormModal
        isLoading={isPending}
        initialData={null}
        onSubmit={onSubmit}
      />
    </DialogContent>
  )
}
