import { CircleX, File, UploadIcon } from 'lucide-react'
import { FieldValues, Path, useFormContext } from 'react-hook-form'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx'
import { cn } from '@/lib/utils.ts'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { buildAttachmentUrl } from '@/lib/build-attachment-url.ts'

interface UploadFileProps<T extends FieldValues> {
  name: Path<T>
  label?: string
  url?: string
  onRemove: () => void
}

interface PreviewProps {
  url: string
}

function Preview({ url }: PreviewProps) {
  const redirectUrl = useMemo(() => {
    if (url.startsWith('blob')) return url
    return buildAttachmentUrl(url)
  }, [url])

  return <File onClick={() => window.open(redirectUrl, '_blank')} />
}

export function UploadFile<T extends FieldValues>({
  label = '',
  name,
  url,
  onRemove,
}: UploadFileProps<T>) {
  const { control } = useFormContext<T>()
  const [previewUrl, setPreviewUrl] = useState(url ?? '')

  useEffect(() => {
    document.addEventListener(
      'drop',
      (e) => {
        e.preventDefault()
      },
      false,
    )
  }, [])

  const handleSetPreviewUrl = useCallback((file?: File) => {
    if (!file) return
    setPreviewUrl(URL.createObjectURL(file as File))
  }, [])

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <>
              <div
                onDrop={(e) => {
                  field.onChange(e.dataTransfer.files[0])
                  handleSetPreviewUrl(e.dataTransfer.files[0])
                }}
                onDragOver={(e) => {
                  e.preventDefault()
                }}
                className={cn(
                  'h-24 w-24 flex items-center justify-center border border-dashed rounded cursor-pointer relative',
                  {
                    'border-spacing-1.5': !!previewUrl,
                    'border-green-500': !!previewUrl,
                  },
                )}
                onClick={() => {
                  const input = document.getElementById(`${name}_id`)
                  if (input) input?.click()
                }}
              >
                {previewUrl ? <Preview url={previewUrl} /> : <UploadIcon />}
                {previewUrl && (
                  <div className={'absolute -top-2.5 -right-2.5'}>
                    <button
                      type={'button'}
                      onClick={(e) => {
                        e.stopPropagation()
                        setPreviewUrl('')
                        field.onChange(null)
                        onRemove()
                      }}
                    >
                      <CircleX color={'red'} className={'h-5 w-5'}></CircleX>
                    </button>
                  </div>
                )}
              </div>

              <input
                {...field}
                accept={'image/*,.pdf'}
                name={name}
                type={'file'}
                id={`${name}_id`}
                className={'hidden'}
                value={undefined}
                onClick={(e) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  e.target.value = null
                }}
                onChange={(e) => {
                  field.onChange(e.target.files?.[0])
                  handleSetPreviewUrl(e.target.files?.[0])
                }}
              />
            </>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
