import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts'

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import { GetValuesByCreditCardOutput } from '@/lib/react-query/requests/dashboard/get-values-by-credit-card.ts'
import { PropsWithChildren, useMemo } from 'react'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale/pt-BR'
import { LoadingContainer } from '@/components/loading-container.tsx'
import { CreditCardsOutput } from '@/lib/react-query/requests/credit_cards/get-credit-cards.ts'

interface ValuesByCreditCardChartProps {
  data: GetValuesByCreditCardOutput | null
  creditCards: CreditCardsOutput['data']
  title: string
  description?: string
  isLoading?: boolean
}

interface BaseCardProps extends PropsWithChildren {
  title: string
  description: string
}

function CardBase({ children, title, description }: BaseCardProps) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent>{children}</CardContent>
    </Card>
  )
}

export function ValuesByCreditCardChart({
  title,
  data,
  description = '',
  isLoading = false,
  creditCards,
}: ValuesByCreditCardChartProps) {
  const chartConfig = useMemo(() => {
    return creditCards?.reduce(
      (acc: Record<string, { label: string; fill: string }>, item) => {
        acc[item.name] = {
          label: item.name,
          fill: item.color,
        }
        return acc satisfies ChartConfig
      },
      {},
    )
  }, [creditCards])

  const chartData = useMemo(() => {
    const output: Record<string, string | number>[] = []

    if (!data) return []

    Object.keys(data).forEach((key: string) => {
      const item = data[key]
      const tmpOutput: Record<string, number> = {}

      item.forEach((value) => {
        tmpOutput[value.credit_card] = value.total || 0
      })

      output.push({
        date: key,
        ...tmpOutput,
      })
    })

    return output
  }, [data])

  if (!chartData.length || !creditCards.length) {
    return (
      <CardBase title={title} description={description}>
        <span className={'flex items-center justify-center my-10'}>
          {isLoading ? <LoadingContainer /> : 'Não há dados para exibir'}
        </span>
      </CardBase>
    )
  }

  return (
    <CardBase title={title} description={description}>
      <ChartContainer config={chartConfig}>
        <BarChart accessibilityLayer data={chartData}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            tickLine={false}
            tickMargin={10}
            axisLine={false}
            tickFormatter={(value) =>
              format(new Date(value), 'MMMM/yy', { locale: ptBR })
            }
          />
          <ChartTooltip
            labelFormatter={(value) =>
              format(new Date(value), 'MMMM/yy', { locale: ptBR })
            }
            content={
              <ChartTooltipContent showValueAs={'currency'} indicator="line" />
            }
          />
          {creditCards.map((cc) => (
            <Bar key={cc.id} dataKey={cc.name} fill={cc.color} radius={4} />
          ))}
        </BarChart>
      </ChartContainer>
    </CardBase>
  )
}
