import { useMemo, useState } from 'react'
import { LoadingContainer } from '@/components/loading-container.tsx'
import { Pagination } from '@/components/pagination.tsx'
import {
  PAGE_SIZE,
  useGetAccounts,
} from '@/lib/react-query/requests/accounts/get-accounts.ts'
import { ListItem } from '@/pages/accounts/components/list-item.tsx'
import { useSearchParams } from 'react-router-dom'

export function AccountsList() {
  const [searchParams] = useSearchParams()

  const [page, setPage] = useState(1)

  const date = useMemo(() => {
    const month = searchParams.get('month')
      ? Number(searchParams.get('month')) - 1
      : new Date().getMonth().toString()
    const year = searchParams.get('year') || new Date().getFullYear().toString()
    return new Date(Number(year), Number(month), 1)
  }, [searchParams])

  const { data, isLoading } = useGetAccounts(
    page,
    PAGE_SIZE,
    date.getMonth() + 1,
    date.getFullYear(),
    0,
  )

  if (!data?.data.length) {
    return (
      <div className="my-10 w-full">
        <span className="flex items-center justify-center text-xl">
          {isLoading ? <LoadingContainer /> : 'Não há dados para exibir'}
        </span>
      </div>
    )
  }

  return (
    <>
      <div className={'grid grid-cols-4 gap-4'}>
        {data?.data?.map((account) => (
          <ListItem key={account.id} account={account} totals={data.totals} />
        ))}
      </div>

      <Pagination
        page={page}
        totalPages={data?.meta.total_pages || 0}
        setPage={setPage}
      />
    </>
  )
}
