import { useQuery } from '@tanstack/react-query'
import { api } from '@/lib/api.ts'
import { GET_VALUES_BY_CREDIT_CARD } from '@/lib/react-query/keys.ts'

export interface GetValuesByCreditCard {
  credit_card: string
  total: number
}

export type GetValuesByCreditCardOutput = Record<
  string,
  GetValuesByCreditCard[]
>

export const useGetValuesByCreditCard = (
  start_date: string,
  end_date: string,
) => {
  return useQuery({
    queryKey: [GET_VALUES_BY_CREDIT_CARD, start_date, end_date],
    enabled: !!start_date && !!end_date,
    queryFn: async () => {
      const { data } = await api.get<GetValuesByCreditCardOutput>(
        `/dashboard/credit_cards`,
        {
          params: {
            start_date,
            end_date,
          },
        },
      )
      return data
    },
  })
}
