import { useQuery } from '@tanstack/react-query'
import { api } from '@/lib/api.ts'
import { GET_ME } from '@/lib/react-query/keys.ts'

interface MeOutput {
  id: string
  name: string
  email: string
  avatar_url: string
  annotation: string
}

const getMe = () => {
  return api.get<MeOutput>('/me')
}

export const useGetMe = () => {
  return useQuery({
    queryKey: [GET_ME],
    staleTime: 1000 * 60 * 60 * 24,
    queryFn: async () => {
      const { data } = await getMe()
      return data
    },
  })
}
