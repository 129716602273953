import { ReactNode } from 'react'
import { useSession } from '@clerk/clerk-react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

export function PrivateRoute({ children }: { children?: ReactNode }) {
  const { isSignedIn, isLoaded } = useSession()
  const navigate = useNavigate()
  const location = useLocation()

  const LOGIN_ROUTE = '/login'
  const INDEX_ROUTE = '/'

  if (!isLoaded) return

  if (!isSignedIn) {
    if (location.pathname === LOGIN_ROUTE) {
      return <>{children || <Outlet />}</>
    }

    navigate(LOGIN_ROUTE)
  }

  if (location.pathname === LOGIN_ROUTE) {
    navigate(INDEX_ROUTE)
    return
  }

  return <>{children || <Outlet />}</>
}
