import {
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'
import { CategoriesOutput } from '@/lib/react-query/requests/categories/get-categories.ts'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteCategory } from '@/lib/react-query/requests/categories/delete-category.ts'
import { toast } from 'sonner'
import { ButtonLoading } from '@/components/form/button-loading.tsx'
import { useCallback } from 'react'
import { GET_CATEGORIES } from '@/lib/react-query/keys.ts'

interface DialogRegisterDialogProps {
  category: CategoriesOutput['data'][0]
}

export function DeleteRegisterDialog({ category }: DialogRegisterDialogProps) {
  const queryClient = useQueryClient()

  const { mutate: deleteRegister, isPending } = useMutation({
    mutationFn: () => deleteCategory(category.id),
    onSuccess: async () => {
      toast.success('Categoria removida com sucesso')
      await queryClient.invalidateQueries({
        queryKey: [GET_CATEGORIES],
      })
      close()
    },
  })

  const close = useCallback(() => {
    const el = document.getElementById('remove-category-close-action')
    if (el) el.click()
  }, [])

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>Tem certeza ?</AlertDialogTitle>
        <AlertDialogDescription>
          Categoria: <strong>{category.name}</strong>
        </AlertDialogDescription>
        <AlertDialogDescription>
          Essa é uma ação irreversível. Deseja continuar ?
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel id={'remove-category-close-action'}>
          Cancelar
        </AlertDialogCancel>
        <ButtonLoading
          className={cn(buttonVariants({ variant: 'destructive' }))}
          isLoading={isPending}
          onClick={() => deleteRegister()}
        >
          Confirmar
        </ButtonLoading>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
