export const GET_CATEGORIES = ['GET_CATEGORIES']
export const GET_ACCOUNTS = ['GET_ACCOUNTS']
export const GET_TRANSACTIONS = ['GET_TRANSACTIONS']
export const GET_CREDIT_CARDS = ['GET_CREDIT_CARDS']
export const GET_CREDIT_CARD_DETAILS = ['GET_CREDIT_CARD_DETAILS']
export const GET_CREDIT_CARD_DATA = ['GET_CREDIT_CARD_DATA']
export const GET_CHECK_CREDIT_CARD_PAYMENT = ['GET_CHECK_CREDIT_CARD_PAYMENT']
export const GET_VALUES_BY_KIND_BY_CATEGORY = ['GET_VALUES_BY_KIND_BY_CATEGORY']
export const GET_VALUES_BY_CREDIT_CARD = ['GET_VALUES_BY_CREDIT_CARD']
export const GET_TOTAL_CREDIT_CARDS_VALUES = ['GET_TOTAL_CREDIT_CARDS_VALUES']
export const GET_ME = ['GET_ME']
