import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ReactNode, useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { GET_CREDIT_CARDS } from '@/lib/react-query/keys.ts'
import {
  CreditCardForm,
  ICreditCardFormType,
} from '@/pages/credit_cards/components/credit-card-form.tsx'
import { createCreditCard } from '@/lib/react-query/requests/credit_cards/create-credit-card.ts'

const CLOSE_DIALOG_ELEMENT_ID = 'create-credit-card-dialog-id'

interface CreateCreditCardModalProps {
  triggerElement: ReactNode
}

export const CreateCreditCardModal = ({
  triggerElement,
}: CreateCreditCardModalProps) => {
  const queryClient = useQueryClient()

  const closeModal = useCallback(() => {
    const el = document.getElementById(CLOSE_DIALOG_ELEMENT_ID)
    if (el) el.click()
  }, [])

  const { mutate: handleCreateCreditCard, isPending } = useMutation({
    mutationFn: createCreditCard,
    onSuccess: async () => {
      toast.success('Cartão de crédito criado com sucesso')
      await queryClient.invalidateQueries({
        queryKey: [GET_CREDIT_CARDS],
      })
      closeModal()
    },
  })

  const onSubmit = useCallback(
    (data: ICreditCardFormType) => {
      handleCreateCreditCard({
        name: data.name,
        due_day: Number(data.due_day),
        closing_day: Number(data.closing_day),
        color: data.color,
        user_account_id: data.user_account_id,
        limit: data.limit,
      })
    },
    [handleCreateCreditCard],
  )

  return (
    <Dialog>
      <DialogTrigger>{triggerElement}</DialogTrigger>
      <DialogContent
        className={'w-[400px]'}
        closeButtonElementId={CLOSE_DIALOG_ELEMENT_ID}
      >
        <DialogHeader>
          <DialogTitle>Novo cartão de crédito</DialogTitle>
        </DialogHeader>
        <CreditCardForm
          initialData={null}
          onSubmit={onSubmit}
          isLoading={isPending}
        />
      </DialogContent>
    </Dialog>
  )
}
