import { ReactNode } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx'
import { Skeleton } from '@/components/ui/skeleton.tsx'

interface StatCardProps {
  label: string
  valueFormatted: string
  icon: ReactNode
  className?: string
  isLoading?: boolean
}

export const StatCard = ({
  label,
  valueFormatted,
  icon,
  className = '',
  isLoading = false,
}: StatCardProps) => {
  return (
    <Card className={className}>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{label}</CardTitle>
        {icon}
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <Skeleton className="w-full h-8" />
        ) : (
          <div className="text-2xl font-bold">{valueFormatted}</div>
        )}
      </CardContent>
    </Card>
  )
}
