import { useState } from 'react'
import { LoadingContainer } from '@/components/loading-container.tsx'
import { Pagination } from '@/components/pagination.tsx'
import {
  PAGE_SIZE,
  useGetCreditCards,
} from '@/lib/react-query/requests/credit_cards/get-credit-cards.ts'
import { ListItem } from '@/pages/credit_cards/components/list-item.tsx'

export function CreditCardsList() {
  const [page, setPage] = useState(1)

  const { data, isLoading } = useGetCreditCards(page, PAGE_SIZE, 0)

  if (!data?.data.length) {
    return (
      <div className="my-10 w-full">
        <span className="flex items-center justify-center text-xl">
          {isLoading ? <LoadingContainer /> : 'Não há dados para exibir'}
        </span>
      </div>
    )
  }

  return (
    <>
      <div className={'grid grid-cols-4 gap-2'}>
        {data?.data?.map((creditCard) => (
          <ListItem key={creditCard.id} creditCard={creditCard} />
        ))}
      </div>

      <Pagination
        page={page}
        totalPages={data?.meta.total_pages || 0}
        setPage={setPage}
      />
    </>
  )
}
