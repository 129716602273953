import {
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { GET_ACCOUNTS } from '@/lib/react-query/keys.ts'
import {
  updateAccount,
  UpdateAccountInput,
} from '@/lib/react-query/requests/accounts/update-account.ts'
import { AccountsOutput } from '@/lib/react-query/requests/accounts/get-accounts.ts'
import {
  AccountFormModal,
  IAccountFormType,
} from '@/pages/accounts/components/account-form-modal.tsx'

const CLOSE_DIALOG_ELEMENT_ID = 'update-account-dialog-id'

interface UpdateAccountModalProps {
  initialData: AccountsOutput['data'][0]
}

export const UpdateAccountModal = ({
  initialData,
}: UpdateAccountModalProps) => {
  const queryClient = useQueryClient()

  const closeModal = useCallback(() => {
    const el = document.getElementById(CLOSE_DIALOG_ELEMENT_ID)
    if (el) el.click()
  }, [])

  const { mutate: handleUpdateAccount, isPending } = useMutation({
    mutationFn: ({ id, data }: { id: string; data: UpdateAccountInput }) =>
      updateAccount(id, data),
    onSuccess: async () => {
      toast.success('Conta atualizada com sucesso')
      await queryClient.invalidateQueries({
        queryKey: [GET_ACCOUNTS],
      })
      closeModal()
    },
  })

  const onSubmit = useCallback(
    (data: IAccountFormType) => {
      handleUpdateAccount({
        id: initialData.id,
        data,
      })
    },
    [handleUpdateAccount, initialData.id],
  )

  return (
    <DialogContent closeButtonElementId={CLOSE_DIALOG_ELEMENT_ID}>
      <DialogHeader>
        <DialogTitle>Editar Conta</DialogTitle>
      </DialogHeader>
      <AccountFormModal
        isLoading={isPending}
        initialData={initialData}
        onSubmit={onSubmit}
      />
    </DialogContent>
  )
}
