import { api } from '@/lib/api.ts'

export interface CreateTransactionInput {
  title: string
  kind: 'income' | 'outcome'
  date: string
  billing_date: string | null
  value: number
  effected?: boolean
  user_category_id: string
  user_credit_card_id: string | null
  user_account_id: string | null
  description?: string | null
  repeat_period?: 'month' | 'year'
  repeat_times?: number
  is_fixed: boolean
}

export async function createTransaction(payload: CreateTransactionInput) {
  await api.post(`/transactions`, payload)
}
