import { useMemo } from 'react'
import { DollarSign, TrendingDown, TrendingUp } from 'lucide-react'
import { StatCard } from '@/pages/transactions/components/stat-card.tsx'
import { formatMoney } from '@/lib/format-money.ts'
import { cn } from '@/lib/utils.ts'

interface CardSectionProps {
  isCreditCard: boolean
  isLoading: boolean
  values: {
    currentBalance: number
    income: number
    outcome: number
    balance: number
  }
}

export const CardSection = ({
  isCreditCard,
  values,
  isLoading,
}: CardSectionProps) => {
  const cards = useMemo(() => {
    const { currentBalance, balance, income, outcome } = values

    if (isCreditCard) {
      return [
        {
          label: 'Total fatura',
          valueFormatted: formatMoney(outcome),
          icon: <DollarSign className="h-4 w-4 text-muted-foreground" />,
        },
      ]
    }

    return [
      {
        label: 'Saldo atual',
        valueFormatted: formatMoney(currentBalance),
        icon: <DollarSign className="h-4 w-4 text-muted-foreground" />,
      },
      {
        label: 'Receitas',
        valueFormatted: formatMoney(income),
        icon: <TrendingUp className="h-4 w-4 text-muted-foreground" />,
      },
      {
        label: 'Despesas',
        valueFormatted: formatMoney(outcome),
        icon: <TrendingDown className="h-4 w-4 text-muted-foreground" />,
      },
      {
        label: 'Saldo',
        valueFormatted: formatMoney(balance),
        icon: <DollarSign className="h-4 w-4 text-muted-foreground" />,
      },
    ]
  }, [isCreditCard, values])

  return (
    <div className={'flex w-full gap-4'}>
      {cards.map((card) => (
        <StatCard
          isLoading={isLoading}
          key={card.label}
          label={card.label}
          icon={card.icon}
          valueFormatted={card.valueFormatted}
          className={cn({
            'flex-grow-0 w-[250px]': isCreditCard,
            'flex-grow': !isCreditCard,
          })}
        />
      ))}
    </div>
  )
}
