import { useQuery } from '@tanstack/react-query'
import { api } from '@/lib/api.ts'
import { GET_TRANSACTIONS } from '@/lib/react-query/keys.ts'

export interface Account {
  id: string
  name: string
}

export interface BankTransfer {
  id: string
  account_to: Account
  account_from: Account
}

interface PaginatedMeta {
  current_page: number
  total_pages: number
  total_count: number
  last_page: boolean
}

export interface Category {
  id: string
  name: string
  color: string
  hide: boolean
}

export interface CreditCard {
  id: string
  name: string
  account: Account
}

export interface Totals {
  income: number
  outcome: number
  current_balance: number
}

interface Transaction {
  id: string
  title: string
  value: number
  description?: string
  date: string
  billing_date: string
  kind: 'income' | 'outcome'
  effected: boolean
  current_installment: number
  total_installments: number
  fixed_transaction_id?: string
  repeat_transaction_id?: string
  created_at: string
  updated_at: string
  receipt_url?: string
  invoice_url?: string
  payment_schedule_receipt_url: string
  account: Account
  category: Category
  bank_transfer?: BankTransfer
  credit_card?: CreditCard
}

export interface TransactionsOutput {
  data: Transaction[]
  totals: Totals
  meta: PaginatedMeta
}

interface TransactionInputFilters {
  query?: string
  account_id?: string
  kind?: string
  category_id?: string
  credit_card_id?: string
  month?: number
  year?: number
  start_date?: string
  end_date?: string
}

export const useGetTransactions = (
  page: number,
  pageSize: number,
  filters: TransactionInputFilters,
) => {
  return useQuery({
    queryKey: [GET_TRANSACTIONS, page, pageSize, filters],
    enabled:
      !!(filters.start_date && filters.end_date) ||
      !!(filters.month && filters.year),
    queryFn: async () => {
      const findByDate = filters.start_date && filters.end_date

      const { data } = await api.get<TransactionsOutput>(`/transactions`, {
        params: {
          page,
          per_page: pageSize,
          month: findByDate ? undefined : filters.month,
          year: findByDate ? undefined : filters.year,
          query: filters.query,
          account_id: filters.account_id,
          kind: filters.kind,
          category_id: filters.category_id,
          credit_card_id: filters.credit_card_id,
          start_date: findByDate ? filters.start_date : undefined,
          end_date: findByDate ? filters.end_date : undefined,
        },
      })
      return data
    },
  })
}
