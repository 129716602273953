import { ReactNode, useCallback, useMemo } from 'react'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetTrigger,
} from '@/components/ui/sheet.tsx'
import { Button } from '@/components/ui/button.tsx'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { InputField } from '@/components/form/input.tsx'
import { SelectField } from '@/components/form/select.tsx'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form } from '@/components/ui/form.tsx'
import { useGetAccounts } from '@/lib/react-query/requests/accounts/get-accounts.ts'
import { useGetCategories } from '@/lib/react-query/requests/categories/get-categories.ts'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

const schema = z.object({
  query: z.string().optional().default(''),
  account_id: z.string().optional().default(''),
  kind: z.enum(['income', 'outcome', 'all']).optional().default('all'),
  category_id: z.string().optional().default(''),
})

type IFiltersSidebarSchema = z.infer<typeof schema>

interface FiltersSidebarProps {
  triggerElement: ReactNode
  creditCardId: string
}

const DEFAULT_VALUES = {
  query: '',
  account_id: '',
  kind: 'all',
  category_id: '',
} satisfies IFiltersSidebarSchema

export const FiltersSidebar = ({
  triggerElement,
  creditCardId,
}: FiltersSidebarProps) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { data: accountsData } = useGetAccounts(1, 100)
  const { data: categoriesData } = useGetCategories(1, 100, {
    name_order: 'asc',
  })

  const accounts = useMemo(() => {
    return accountsData?.data || []
  }, [accountsData])

  const categories = useMemo(() => {
    return categoriesData?.data || []
  }, [categoriesData])

  const form = useForm<IFiltersSidebarSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      query: searchParams.get('query') || DEFAULT_VALUES.query,
      account_id: searchParams.get('account_id') || DEFAULT_VALUES.account_id,
      kind: (searchParams.get('kind') ||
        DEFAULT_VALUES.kind) as IFiltersSidebarSchema['kind'],
      category_id:
        searchParams.get('category_id') || DEFAULT_VALUES.category_id,
    },
  })

  const setURLParam = useCallback(
    (params: URLSearchParams, key: string, value?: string | null) => {
      if (value) {
        params.set(key, value)
      } else {
        params.delete(key)
      }
    },
    [],
  )

  const onSearch = useCallback(
    (data: IFiltersSidebarSchema) => {
      const params = new URLSearchParams(searchParams.toString())
      setURLParam(params, 'query', data.query)
      setURLParam(params, 'account_id', data.account_id)
      setURLParam(params, 'kind', data.kind !== 'all' ? data.kind : null)
      setURLParam(params, 'category_id', data.category_id)
      params.delete('page')
      navigate(`${pathname}?${params.toString()}`)
    },
    [navigate, pathname, searchParams, setURLParam],
  )

  return (
    <Sheet>
      <SheetTrigger asChild>{triggerElement}</SheetTrigger>
      <SheetContent>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSearch)}
            className={'flex flex-col gap-4'}
          >
            <InputField<IFiltersSidebarSchema>
              name={'query'}
              label={'Pesquisa'}
              inputProps={{
                placeholder: 'Pesquisar',
              }}
            />

            {!creditCardId && (
              <SelectField<IFiltersSidebarSchema>
                name={'account_id'}
                label={'Conta'}
                options={accounts.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                placeholder={'Conta'}
              />
            )}

            {!creditCardId && (
              <SelectField<IFiltersSidebarSchema>
                name={'kind'}
                label={'Tipo'}
                options={[
                  {
                    label: 'Todos',
                    value: 'all',
                  },
                  {
                    label: 'Receita',
                    value: 'income',
                  },
                  {
                    label: 'Despesa',
                    value: 'outcome',
                  },
                ]}
                placeholder={'Tipo'}
              />
            )}

            <SelectField<IFiltersSidebarSchema>
              name={'category_id'}
              label={'Categoria'}
              options={categories.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              placeholder={'Categoria'}
            />

            <SheetFooter>
              <SheetClose asChild>
                <Button
                  onClick={() => {
                    form.reset(DEFAULT_VALUES)
                    onSearch(form.getValues())
                  }}
                  variant={'outline'}
                  type="button"
                >
                  Limpar
                </Button>
              </SheetClose>
              <SheetClose asChild>
                <Button type="submit">Buscar</Button>
              </SheetClose>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  )
}
