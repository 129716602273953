import {
  Account,
  Totals,
} from '@/lib/react-query/requests/accounts/get-accounts.ts'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.tsx'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { DotsVerticalIcon } from '@radix-ui/react-icons'
import { List, Pencil, Trash } from 'lucide-react'
import { useMemo } from 'react'
import { Button } from '@/components/ui/button.tsx'
import {
  AlertDialog,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog.tsx'
import { DeleteRegisterDialog } from '@/pages/accounts/components/delete-register-dialog.tsx'
import { Dialog, DialogTrigger } from '@/components/ui/dialog.tsx'
import { UpdateAccountModal } from '@/pages/accounts/components/update-account-modal.tsx'
import { formatMoney } from '@/lib/format-money.ts'
import { useNavigate } from 'react-router-dom'

interface ListItemProps {
  account: Account
  totals: Totals
}

export const ListItem = ({ account, totals }: ListItemProps) => {
  const navigate = useNavigate()

  const currentBalance = useMemo(() => {
    return totals.current_balance[account.id] || 0
  }, [account.id, totals.current_balance])

  const predicateBalance = useMemo(() => {
    return totals.predicated_balance[account.id] || 0
  }, [account.id, totals.predicated_balance])

  return (
    <Card key={account.id}>
      <CardHeader className="pb-3 flex justify-between flex-row items-center">
        <CardTitle>{account.name}</CardTitle>
        <Dialog>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant={'ghost'} size={'icon'}>
                <DotsVerticalIcon />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>Ações</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <Dialog>
                <DropdownMenuItem
                  onClick={() => {
                    navigate(`/transacoes?account_id=${account.id}`)
                  }}
                >
                  <List className="mr-2 h-4 w-4" />
                  <span>Acessar Transações</span>
                </DropdownMenuItem>
                <DropdownMenuItem asChild>
                  <>
                    <DialogTrigger
                      className={
                        'w-full relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-accent focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
                      }
                    >
                      <Pencil className="mr-2 h-4 w-4" />
                      <span>Editar</span>
                    </DialogTrigger>
                    <UpdateAccountModal initialData={account} />
                  </>
                </DropdownMenuItem>
              </Dialog>
              <AlertDialog>
                <DropdownMenuItem asChild>
                  <>
                    <AlertDialogTrigger
                      className={
                        'w-full relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-accent focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
                      }
                    >
                      <Trash color={'red'} className="mr-2 h-4 w-4" />
                      <span>Remover</span>
                    </AlertDialogTrigger>
                    <DeleteRegisterDialog account={account} />
                  </>
                </DropdownMenuItem>
              </AlertDialog>
            </DropdownMenuContent>
          </DropdownMenu>
        </Dialog>
      </CardHeader>

      <CardContent className={'flex flex-col gap-2'}>
        <div className="text-sm text-muted-foreground flex justify-between">
          <span>Saldo atual:</span>
          <span>{formatMoney(currentBalance)}</span>
        </div>
        <div className="text-sm text-muted-foreground flex justify-between">
          <span>Saldo previsto:</span>
          <span>{formatMoney(predicateBalance)}</span>
        </div>
      </CardContent>
    </Card>
  )
}
