import { FieldValues, Path, useFormContext } from 'react-hook-form'
import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'

import { Button } from '@/components/ui/button'
import { Calendar, CalendarProps } from '@/components/ui/calendar'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'

interface DatePickerFormProps<T extends FieldValues> {
  name: Path<T>
  label: string
  placeholder?: string
  calendarProps?: Omit<CalendarProps, 'onSelect'>
}

export function DatePickerForm<T extends FieldValues>({
  name,
  label,
  placeholder = '',
  calendarProps,
}: DatePickerFormProps<T>) {
  const form = useFormContext<T>()

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>{label}</FormLabel>
          <Popover>
            <PopoverTrigger disabled={!!calendarProps?.disabled} asChild>
              <FormControl>
                <Button
                  variant={'outline'}
                  className={cn(
                    'w-full text-left font-normal',
                    !field.value && 'text-muted-foreground',
                  )}
                >
                  {field.value ? (
                    format(field.value, 'dd/MM/yyyy')
                  ) : (
                    <span>{placeholder}</span>
                  )}
                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                {...calendarProps}
                selected={field.value}
                onSelect={field.onChange}
                initialFocus
                defaultMonth={
                  (field.value as Date) instanceof Date
                    ? field.value
                    : new Date()
                }
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
