import { z } from 'zod'
import { Form } from '@/components/ui/form.tsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { InputField } from '@/components/form/input.tsx'
import { ButtonLoading } from '@/components/form/button-loading.tsx'

const DEFAULT_COLOR = '#000'

const schema = z.object({
  name: z.string().min(1, 'Campo obrigatório').default(''),
  color: z.string().min(1, 'Campo obrigatório').default(DEFAULT_COLOR),
})

export type ICategoryFormType = z.infer<typeof schema>

interface CategoryFormModalProps {
  onSubmit: (data: ICategoryFormType) => void
  isLoading: boolean
  initialData: {
    id: string
    name: string
    color: string
  } | null
}

export const CategoryFormModal = ({
  initialData,
  onSubmit,
  isLoading = false,
}: CategoryFormModalProps) => {
  const form = useForm<ICategoryFormType>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: initialData?.name || '',
      color: initialData?.color || DEFAULT_COLOR,
    },
  })

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-6"
      >
        <InputField<ICategoryFormType>
          name="name"
          label="Nome"
          inputProps={{
            placeholder: 'Nome da categoria',
          }}
        />
        <InputField<ICategoryFormType>
          name="color"
          label="Cor"
          inputProps={{
            type: 'color',
          }}
        />
        <ButtonLoading type={'submit'} isLoading={isLoading}>
          <span>Salvar</span>
        </ButtonLoading>
      </form>
    </Form>
  )
}
