import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog.tsx'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { ButtonLoading } from '@/components/form/button-loading.tsx'
import { ReactNode, useCallback, useMemo } from 'react'
import { CreditCardsOutput } from '@/lib/react-query/requests/credit_cards/get-credit-cards.ts'
import {
  GET_CHECK_CREDIT_CARD_PAYMENT,
  GET_CREDIT_CARD_DATA,
  GET_TRANSACTIONS,
} from '@/lib/react-query/keys.ts'
import { undoPayCreditCard } from '@/lib/react-query/requests/credit_cards/undo-pay-credit-card.ts'
import { payCreditCard } from '@/lib/react-query/requests/credit_cards/pay-credit-card.ts'
import { SelectField } from '@/components/form/select.tsx'
import { ITransactionFormType } from '@/pages/transactions/components/transaction-form.tsx'
import { Form } from '@/components/ui/form.tsx'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useGetAccounts } from '@/lib/react-query/requests/accounts/get-accounts.ts'

const formSchema = z.object({
  user_account_id: z.string().min(1, 'Campo obrigatório').default(''),
})

type IFormSchema = z.infer<typeof formSchema>

interface DialogRegisterDialogProps {
  isPaid: boolean
  triggerElement: ReactNode
  creditCard: Pick<CreditCardsOutput['data'][0], 'id' | 'name'>
  month: number
  year: number
  userAccountId: string
}

const CLOSE_BUTTON_ID = 'remove-payment-credit-card-close-action'

export function PaymentDialog({
  isPaid,
  triggerElement,
  creditCard,
  month,
  year,
  userAccountId,
}: DialogRegisterDialogProps) {
  const queryClient = useQueryClient()

  const { data: accountsData } = useGetAccounts(1, 100)

  const accounts = useMemo(() => {
    return accountsData?.data || []
  }, [accountsData?.data])

  const form = useForm<IFormSchema>({
    resolver: zodResolver(formSchema),
    values: {
      user_account_id: userAccountId,
    },
  })

  const { mutate: handlePayment, isPending } = useMutation({
    mutationFn: ({ data }: { data: IFormSchema }) => {
      if (isPaid) {
        return undoPayCreditCard(creditCard.id, {
          month,
          year,
        })
      }

      return payCreditCard(creditCard.id, {
        month,
        year,
        user_account_id: data.user_account_id || undefined,
      })
    },
    onSuccess: async () => {
      toast.success(
        isPaid
          ? 'O pagamento do cartão de crédito foi removido com sucesso'
          : 'Cartão de crédito pago com sucesso',
      )
      close()
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [GET_TRANSACTIONS],
        }),
        queryClient.invalidateQueries({
          queryKey: [GET_CREDIT_CARD_DATA],
        }),
        queryClient.invalidateQueries({
          queryKey: [GET_CHECK_CREDIT_CARD_PAYMENT],
        }),
      ])
    },
  })

  const close = useCallback(() => {
    const el = document.getElementById(CLOSE_BUTTON_ID)
    if (el) el.click()
  }, [])

  return (
    <AlertDialog>
      <AlertDialogTrigger>{triggerElement}</AlertDialogTrigger>
      <AlertDialogContent>
        <Form {...form}>
          <form
            className={'flex flex-col gap-4'}
            onSubmit={form.handleSubmit((data) => handlePayment({ data }))}
          >
            <AlertDialogHeader>
              <AlertDialogTitle>Tem certeza ?</AlertDialogTitle>
              <AlertDialogDescription>
                Cartão de crédito: <strong>{creditCard.name}</strong>
              </AlertDialogDescription>
              <AlertDialogDescription>
                Fatura:{' '}
                <strong>
                  {month.toString().padStart(2, '0')}/{year}
                </strong>
              </AlertDialogDescription>
              <AlertDialogDescription>
                {isPaid ? (
                  <span>
                    Deseja <strong>desfazer</strong> o pagamento do cartão de
                    crédito ?
                  </span>
                ) : (
                  <span>
                    Deseja <strong>confirmar</strong> o pagamento do cartão de
                    crédito ?
                  </span>
                )}

                {!isPaid && (
                  <SelectField<ITransactionFormType>
                    name={'user_account_id'}
                    label={'Conta'}
                    placeholder={'Selecione a conta'}
                    options={accounts.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  />
                )}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel id={CLOSE_BUTTON_ID}>
                Cancelar
              </AlertDialogCancel>
              <ButtonLoading type={'submit'} isLoading={isPending}>
                Confirmar
              </ButtonLoading>
            </AlertDialogFooter>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  )
}
