import { Pie, PieChart } from 'recharts'

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import { GetValuesByCategoriesByKind } from '@/lib/react-query/requests/dashboard/get-values-by-categories-by-kind.ts'
import { PropsWithChildren, useMemo } from 'react'
import { LoadingContainer } from '@/components/loading-container.tsx'

interface ValuesByCategoryChartProps {
  data: GetValuesByCategoriesByKind[]
  title: string
  description?: string
  isLoading?: boolean
}

interface CardBaseProps extends PropsWithChildren {
  title: string
  description: string
}

function CardBase({ title, description, children }: CardBaseProps) {
  return (
    <Card className="flex flex-col flex-grow">
      <CardHeader className="items-center pb-0">
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>

      <CardContent className="flex-1 pb-0">{children}</CardContent>
    </Card>
  )
}

export function ValuesByCategoryChart({
  data,
  title,
  description = '',
  isLoading = false,
}: ValuesByCategoryChartProps) {
  const chartConfig = useMemo(() => {
    return data.reduce((acc: ChartConfig, item) => {
      acc[item.name] = {
        label: item.name,
      }
      return acc satisfies ChartConfig
    }, {})
  }, [data])

  const chartData = useMemo(() => {
    return data.map((item) => ({
      category: item.name,
      value: item.total,
      fill: item.color,
    }))
  }, [data])

  if (!data.length) {
    return (
      <CardBase title={title} description={description}>
        <span className={'flex items-center justify-center my-10'}>
          {isLoading ? <LoadingContainer /> : 'Não há dados para exibir'}
        </span>
      </CardBase>
    )
  }

  return (
    <CardBase title={title} description={description}>
      <ChartContainer
        config={chartConfig}
        className="mx-auto aspect-square max-h-[250px]"
      >
        <PieChart>
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent showValueAs={'currency'} hideLabel />}
          />
          <Pie data={chartData} dataKey="value" nameKey="category" />
        </PieChart>
      </ChartContainer>
    </CardBase>
  )
}
