import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ReactNode, useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import {
  GET_CREDIT_CARD_DATA,
  GET_TRANSACTIONS,
} from '@/lib/react-query/keys.ts'
import { DropdownMenuItem } from '@/components/ui/dropdown-menu.tsx'
import {
  ITransactionFormType,
  TransactionForm,
} from '@/pages/transactions/components/transaction-form.tsx'
import { useGetAccounts } from '@/lib/react-query/requests/accounts/get-accounts.ts'
import { useGetCategories } from '@/lib/react-query/requests/categories/get-categories.ts'
import { useGetCreditCards } from '@/lib/react-query/requests/credit_cards/get-credit-cards.ts'
import {
  createTransaction,
  CreateTransactionInput,
} from '@/lib/react-query/requests/transactions/create-transaction.ts'
import { format, parseISO } from 'date-fns'

const CLOSE_DIALOG_ELEMENT_ID = 'create-transaction-dialog-id'

interface CreateTransactionModalProps {
  triggerElement: ReactNode
  initialData: {
    kind: 'income' | 'outcome'
    creditCardId?: string
    accountId?: string
  }
  showCreditCardInput?: boolean
}

export const CreateTransactionModal = ({
  triggerElement,
  initialData,
  showCreditCardInput,
}: CreateTransactionModalProps) => {
  const queryClient = useQueryClient()

  const { data: accounts } = useGetAccounts(1, 100)
  const { data: categories } = useGetCategories(1, 100, {
    name_order: 'asc',
  })
  const { data: creditCards } = useGetCreditCards(1, 100)

  const closeModal = useCallback(() => {
    const el = document.getElementById(CLOSE_DIALOG_ELEMENT_ID)
    if (el) el.click()
  }, [])

  const { mutate: handleCreateTransaction, isPending } = useMutation({
    mutationFn: (data: ITransactionFormType) => {
      const payload: CreateTransactionInput = {
        kind: data.kind,
        date: format(parseISO(data.date.toISOString()), 'yyyy-MM-dd'),
        description: data.description ?? null,
        is_fixed: data.is_fixed,
        value: data.value,
        title: data.title,
        user_account_id: data.user_account_id,
        user_category_id: data.user_category_id,
        user_credit_card_id: data.user_credit_card_id || null,
        effected: data.effected,
        billing_date: format(parseISO(data.date.toISOString()), 'yyyy-MM-dd'),
      }

      if (data.repeat && !data.is_fixed) {
        payload.repeat_period = data.repeat_period
        payload.repeat_times = data.repeat_times
      }

      if (data.user_credit_card_id) {
        delete payload.effected

        if (data.billing_date) {
          Object.assign(payload, {
            billing_date: data.billing_date,
          })
        }
      }

      return createTransaction(payload)
    },
    onSuccess: async () => {
      toast.success('Transação criada com sucesso')
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [GET_TRANSACTIONS],
        }),
        queryClient.invalidateQueries({
          queryKey: [GET_CREDIT_CARD_DATA],
        }),
      ])
      closeModal()
    },
  })

  const onSubmit = useCallback(
    (data: ITransactionFormType) => {
      handleCreateTransaction(data)
    },
    [handleCreateTransaction],
  )

  return (
    <Dialog>
      <DropdownMenuItem asChild>
        <>
          <DialogTrigger
            className={
              'w-full relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-accent focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
            }
          >
            {triggerElement}
          </DialogTrigger>
        </>
      </DropdownMenuItem>
      <DialogContent
        className={'max-w-[800px] w-max'}
        closeButtonElementId={CLOSE_DIALOG_ELEMENT_ID}
      >
        <DialogHeader>
          <DialogTitle>Nova Transação</DialogTitle>
        </DialogHeader>
        <TransactionForm
          accounts={accounts?.data || []}
          categories={categories?.data || []}
          creditCards={creditCards?.data || []}
          isLoading={isPending}
          transaction={null}
          onSubmit={onSubmit}
          initialData={initialData}
          showCreditCardInput={showCreditCardInput}
        />
      </DialogContent>
    </Dialog>
  )
}
