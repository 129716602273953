import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ReactNode, useCallback, useMemo } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Form } from '@/components/ui/form.tsx'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ButtonLoading } from '@/components/form/button-loading.tsx'
import { toast } from 'sonner'
import {
  GET_CREDIT_CARD_DATA,
  GET_TRANSACTIONS,
} from '@/lib/react-query/keys.ts'
import { Tooltip } from '@/components/tooltip.tsx'
import { SelectField } from '@/components/form/select.tsx'
import { ITransactionFormType } from '@/pages/transactions/components/transaction-form.tsx'
import { useGetAccounts } from '@/lib/react-query/requests/accounts/get-accounts.ts'
import { changeTransactionsAccount } from '@/lib/react-query/requests/credit_cards/change-transactions-account.ts'

const CLOSE_DIALOG_ELEMENT_ID = 'change-credit-card-account-dialog-id'

interface ChangeCreditCardAccountDialogProps {
  payload: {
    id: string
    user_account_id: string
    credit_card_name: string
    month: number
    year: number
  }
  triggerElement: ReactNode
}

const changeCreditCardAccountSchema = z.object({
  user_account_id: z.string().min(1, 'Campo obrigatório'),
})

type IChangeCreditCardAccountSchema = z.infer<
  typeof changeCreditCardAccountSchema
>

export const ChangeCreditCardAccountDialog = ({
  payload,
  triggerElement,
}: ChangeCreditCardAccountDialogProps) => {
  const { data: accountsData } = useGetAccounts(1, 100)

  const accounts = useMemo(() => {
    return accountsData?.data || []
  }, [accountsData?.data])

  const form = useForm<IChangeCreditCardAccountSchema>({
    resolver: zodResolver(changeCreditCardAccountSchema),
    values: {
      user_account_id: payload.user_account_id,
    },
  })

  const queryClient = useQueryClient()

  const closeModal = useCallback(() => {
    const el = document.getElementById(CLOSE_DIALOG_ELEMENT_ID)
    if (el) el.click()
    form.reset()
  }, [form])

  const { mutate: handleChangeAccount, isPending } = useMutation({
    mutationFn: async (data: IChangeCreditCardAccountSchema) => {
      return changeTransactionsAccount(payload.id, {
        month: payload.month,
        year: payload.year,
        user_account_id: data.user_account_id,
      })
    },
    onSuccess: async () => {
      toast.success('Conta atualizada com sucesso')
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [GET_CREDIT_CARD_DATA],
        }),
        queryClient.invalidateQueries({
          queryKey: [GET_TRANSACTIONS],
        }),
      ])

      closeModal()
    },
  })

  const onSubmit = useCallback(
    async (data: IChangeCreditCardAccountSchema) => {
      handleChangeAccount(data)
    },
    [handleChangeAccount],
  )

  return (
    <Dialog onOpenChange={() => form.reset()}>
      <Tooltip title={'Anexos'}>
        <div>
          <DialogTrigger>{triggerElement}</DialogTrigger>
          <DialogContent closeButtonElementId={CLOSE_DIALOG_ELEMENT_ID}>
            <DialogHeader>
              <DialogTitle>
                Alterar conta para faturamento - {payload.credit_card_name}
              </DialogTitle>
            </DialogHeader>

            <Form {...form}>
              <form
                className={'flex flex-col gap-4'}
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <div className={'flex gap-6'}>
                  <SelectField<ITransactionFormType>
                    name={'user_account_id'}
                    label={'Conta'}
                    placeholder={'Selecione a conta'}
                    options={accounts.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  />
                </div>

                <ButtonLoading type={'submit'} isLoading={isPending}>
                  <span>Salvar</span>
                </ButtonLoading>
              </form>
            </Form>
          </DialogContent>
        </div>
      </Tooltip>
    </Dialog>
  )
}
