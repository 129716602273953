import { z } from 'zod'
import { Form } from '@/components/ui/form.tsx'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { InputField } from '@/components/form/input.tsx'
import { ButtonLoading } from '@/components/form/button-loading.tsx'

const schema = z.object({
  name: z.string().min(1, 'Campo obrigatório').default(''),
})




export type IAccountFormType = z.infer<typeof schema>

interface CategoryFormModalProps {
  onSubmit: (data: IAccountFormType) => void
  isLoading: boolean
  initialData: {
    id: string
    name: string
  } | null
}

export const AccountFormModal = ({
  initialData,
  onSubmit,
  isLoading = false,
}: CategoryFormModalProps) => {
  const form = useForm<IAccountFormType>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: initialData?.name || '',
    },
  })

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-6"
      >
        <InputField<IAccountFormType>
          name="name"
          label="Nome"
          inputProps={{
            placeholder: 'Nome da Conta',
          }}
        />
        <ButtonLoading type={'submit'} isLoading={isLoading}>
          <span>Salvar</span>
        </ButtonLoading>
      </form>
    </Form>
  )
}
