import { api } from '@/lib/api.ts'

export interface UpdateCategoryInput {
  name: string
  color: string
}

export const updateCategory = async (id: string, data: UpdateCategoryInput) => {
  await api.put(`/categories/${id}`, data)
}
