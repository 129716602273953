import { useQuery } from '@tanstack/react-query'
import { api } from '@/lib/api.ts'
import { GET_CREDIT_CARDS } from '@/lib/react-query/keys.ts'

export const PAGE_SIZE = 100

interface CreditCardAccount {
  id: string
  name: string
}

interface CreditCard {
  id: string
  name: string
  limit: number
  color: string
  due_day: number
  closing_day: number
  invoices: {
    current: {
      total: number
      period: string
    }
    previous: {
      total: number
      period: string
    }
  }
  account: CreditCardAccount
}

interface PaginatedMeta {
  current_page: number
  total_pages: number
  total_count: number
  last_page: boolean
}

export interface CreditCardsOutput {
  data: CreditCard[]
  meta: PaginatedMeta
}

export const useGetCreditCards = (
  page: number,
  perPage: number,
  staleTime: number = 1000 * 60 * 5,
) => {
  return useQuery({
    queryKey: [GET_CREDIT_CARDS, page, perPage],
    staleTime,
    queryFn: async () => {
      const { data } = await api.get<CreditCardsOutput>(`/credit_cards`, {
        params: {
          page,
          per_page: perPage,
        },
      })
      return data
    },
  })
}
