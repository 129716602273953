import { useQuery } from '@tanstack/react-query'
import { api } from '@/lib/api.ts'
import { GET_TOTAL_CREDIT_CARDS_VALUES } from '@/lib/react-query/keys.ts'

export interface GetTotalCreditCardsValues {
  monthly_income: number
  monthly_outcome: number
  total_balance: number
  credit_cards_total_amount: number
}

export const useGetTotalCreditCardsValues = (
  start_date: string,
  end_date: string,
) => {
  return useQuery({
    queryKey: [GET_TOTAL_CREDIT_CARDS_VALUES, start_date, end_date],
    enabled: !!start_date && !!end_date,
    queryFn: async () => {
      const { data } = await api.get<GetTotalCreditCardsValues>(
        `/dashboard/cards`,
        {
          params: {
            start_date,
            end_date,
          },
        },
      )
      return data
    },
  })
}
