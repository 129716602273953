import { useMemo } from 'react'
import { CreditCard, DollarSign, TrendingDown, TrendingUp } from 'lucide-react'
import { StatCard } from '@/pages/transactions/components/stat-card.tsx'
import { formatMoney } from '@/lib/format-money.ts'

interface CardSectionProps {
  isLoading: boolean
  values: {
    totalBalance: number
    monthlyIncome: number
    monthlyOutcome: number
    creditCardsTotalAmount: number
  }
}

export const CardSection = ({ values, isLoading }: CardSectionProps) => {
  const cards = useMemo(() => {
    const {
      totalBalance,
      creditCardsTotalAmount,
      monthlyIncome,
      monthlyOutcome,
    } = values

    return [
      {
        label: 'Saldo atual',
        valueFormatted: formatMoney(totalBalance),
        icon: <DollarSign className="h-4 w-4 text-muted-foreground" />,
      },
      {
        label: 'Receitas',
        valueFormatted: formatMoney(monthlyIncome),
        icon: <TrendingUp className="h-4 w-4 text-muted-foreground" />,
      },
      {
        label: 'Despesas',
        valueFormatted: formatMoney(monthlyOutcome),
        icon: <TrendingDown className="h-4 w-4 text-muted-foreground" />,
      },
      {
        label: 'Cartão de crédito',
        valueFormatted: formatMoney(creditCardsTotalAmount),
        icon: <CreditCard className="h-4 w-4 text-muted-foreground" />,
      },
    ]
  }, [values])

  return (
    <div className={'flex w-full gap-4'}>
      {cards.map((card) => (
        <StatCard
          isLoading={isLoading}
          key={card.label}
          label={card.label}
          icon={card.icon}
          valueFormatted={card.valueFormatted}
          className={'flex-grow'}
        />
      ))}
    </div>
  )
}
