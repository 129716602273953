import { api } from '@/lib/api.ts'

export interface CreateCategoryInput {
  name: string
  color: string
}

export const createCategory = async (data: CreateCategoryInput) => {
  await api.post('/categories', data)
}
