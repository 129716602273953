import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { useCallback } from 'react'
import { BankTransfer } from '@/lib/react-query/requests/transactions/get-transactions.ts'
import { GET_TRANSACTIONS } from '@/lib/react-query/keys.ts'
import { formatMoney } from '@/lib/format-money.ts'
import { Tooltip } from '@/components/tooltip.tsx'
import { Trash } from 'lucide-react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table.tsx'
import { deleteBankTransfer } from '@/lib/react-query/requests/bank_transfers/delete-bank-transfer.ts'
import { ButtonLoading } from '@/components/form/button-loading.tsx'
import { cn } from '@/lib/utils.ts'
import { buttonVariants } from '@/components/ui/button.tsx'

interface BankTransferType extends BankTransfer {
  value: number
}

interface DeleteBankTransferDialogProps {
  bank_transfer: BankTransferType
}

const CLOSE_DIALOG_ID = 'remove-bank-transfer-close-action'

export function DeleteBankTransferDialog({
  bank_transfer,
}: DeleteBankTransferDialogProps) {
  const queryClient = useQueryClient()

  const { mutate: deleteRegister, isPending } = useMutation({
    mutationFn: () => deleteBankTransfer(bank_transfer.id),
    onSuccess: async () => {
      toast.success('Transferência removida com sucesso.')
      await queryClient.invalidateQueries({
        queryKey: [GET_TRANSACTIONS],
      })
      close()
    },
  })

  const close = useCallback(() => {
    const el = document.getElementById(CLOSE_DIALOG_ID)
    if (el) el.click()
  }, [])

  return (
    <AlertDialog>
      <Tooltip title={'Remover'}>
        <div>
          <AlertDialogTrigger asChild>
            <button>
              <Trash color={'red'} size={16} />
            </button>
          </AlertDialogTrigger>

          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Remover Transferência</AlertDialogTitle>
            </AlertDialogHeader>

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>De</TableHead>
                  <TableHead>Para</TableHead>
                  <TableHead>Valor</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow className={'h-12'} key={bank_transfer.id}>
                  <TableCell>{bank_transfer.account_from.name}</TableCell>
                  <TableCell>{bank_transfer.account_to.name}</TableCell>
                  <TableCell>{formatMoney(bank_transfer.value)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <AlertDialogFooter>
              <AlertDialogCancel id={CLOSE_DIALOG_ID}>
                Cancelar
              </AlertDialogCancel>
              <ButtonLoading
                type={'button'}
                onClick={() => deleteRegister()}
                className={cn(buttonVariants({ variant: 'destructive' }))}
                isLoading={isPending}
              >
                Confirmar
              </ButtonLoading>
            </AlertDialogFooter>
          </AlertDialogContent>
        </div>
      </Tooltip>
    </AlertDialog>
  )
}
