import { TransactionsTable } from '@/pages/transactions/components/table.tsx'
import MonthPicker from '@/components/ui/month-picker.tsx'
import { useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
  ArrowLeftRight,
  CreditCard,
  DollarSign,
  Filter,
  Plus,
  TrendingDown,
  TrendingUp,
} from 'lucide-react'
import { Button } from '@/components/ui/button.tsx'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx'
import { CreateTransactionModal } from '@/pages/transactions/components/create-transaction-modal.tsx'
import { CreateBankTransferModal } from '@/pages/transactions/components/create-bank-transfer-modal.tsx'
import { DialogTrigger } from '@/components/ui/dialog.tsx'
import { FiltersSidebar } from '@/pages/transactions/components/filters-sidebar.tsx'
import { useGetCreditCardDetails } from '@/lib/react-query/requests/credit_cards/get-credit-card-details.ts'
import { Tooltip } from '@/components/tooltip.tsx'
import { useCheckCreditCardPayment } from '@/lib/react-query/requests/credit_cards/check-credit-card-payment.ts'
import { cn } from '@/lib/utils.ts'
import { CardSection } from '@/pages/transactions/components/cards-section.tsx'
import { useGetTransactions } from '@/lib/react-query/requests/transactions/get-transactions.ts'
import { DatePickerWithRange } from '@/components/ui/date-picker-range.tsx'
import { DateRange } from 'react-day-picker'
import { endOfMonth, format, parseISO, set, startOfMonth } from 'date-fns'
import { CreditCardsTable } from '@/pages/transactions/components/credit-cards-table.tsx'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs.tsx'
import { useGetCreditCardsData } from '@/lib/react-query/requests/transactions/get-credit-cards-data.ts'
import { PaymentDialog } from '@/components/dialogs/payment-dialog.tsx'

interface TransactionsProps {
  creditCardId?: string
}

export function Transactions({ creditCardId }: TransactionsProps) {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [dateRange, setDateRange] = useState<DateRange | undefined>(() => {
    const queryStartDate = searchParams.get('start_date')
    const queryEndDate = searchParams.get('end_date')

    const startDate = queryStartDate
      ? parseISO(queryStartDate)
      : startOfMonth(new Date())

    const endDate = queryEndDate
      ? parseISO(queryEndDate)
      : endOfMonth(new Date())

    return {
      from: set(startDate, {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      to: set(endDate, {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
    }
  })

  const formattedDates = useMemo(() => {
    return {
      startDate: dateRange?.from ? format(dateRange.from, 'yyyy-MM-dd') : '',
      endDate: dateRange?.to ? format(dateRange.to, 'yyyy-MM-dd') : '',
    }
  }, [dateRange])

  const date = useMemo(() => {
    const month = searchParams.get('month')
      ? Number(searchParams.get('month')) - 1
      : new Date().getMonth().toString()
    const year = searchParams.get('year') || new Date().getFullYear().toString()
    return new Date(Number(year), Number(month), 1)
  }, [searchParams])

  const page = useMemo(() => {
    const page = searchParams.get('page') || 1
    return Number(page)
  }, [searchParams])

  const pageSize = useMemo(() => {
    const pageSize = searchParams.get('per_page') || 50
    return Number(pageSize)
  }, [searchParams])

  const onChangeDateRange = useCallback(
    (selectedDateRange: DateRange | undefined) => {
      if (!selectedDateRange) return

      setDateRange(selectedDateRange)

      if (selectedDateRange?.from && selectedDateRange.to) {
        const params = new URLSearchParams(searchParams.toString())
        params.set('start_date', format(selectedDateRange.from, 'yyyy-MM-dd'))
        params.set('end_date', format(selectedDateRange.to, 'yyyy-MM-dd'))
        params.delete('page')
        navigate(`${pathname}?${params.toString()}`)
      }
    },
    [navigate, pathname, searchParams],
  )

  const changeDate = useCallback(
    (date: Date) => {
      const params = new URLSearchParams(searchParams.toString())
      params.set('month', (date.getMonth() + 1).toString())
      params.set('year', date.getFullYear().toString())
      params.delete('page')
      navigate(`${pathname}?${params.toString()}`)
    },
    [navigate, pathname, searchParams],
  )

  const selectedTab = useMemo(() => {
    return searchParams.get('tab') || 'transactions'
  }, [searchParams])

  const onChangeTab = useCallback(
    (selectedTab: string) => {
      const params = new URLSearchParams(searchParams.toString())
      params.set('tab', selectedTab)
      navigate(`${pathname}?${params.toString()}`)
    },
    [navigate, pathname, searchParams],
  )

  const { data: creditCardsData, isLoading: creditCardsDataIsLoading } =
    useGetCreditCardsData({
      account_id: searchParams.get('account_id') || undefined,
      start_date: creditCardId ? undefined : formattedDates.startDate,
      end_date: creditCardId ? undefined : formattedDates.endDate,
    })

  const { data: creditCardDetails } = useGetCreditCardDetails(creditCardId)
  const { data: isPaidData } = useCheckCreditCardPayment(
    creditCardId || '',
    date.getMonth() + 1,
    date.getFullYear(),
  )
  const filters = useMemo(() => {
    return {
      query: searchParams.get('query') || undefined,
      account_id: searchParams.get('account_id') || undefined,
      kind: searchParams.get('kind') || undefined,
      category_id: searchParams.get('category_id') || undefined,
      credit_card_id: creditCardId,
      start_date: creditCardId ? undefined : formattedDates.startDate,
      end_date: creditCardId ? undefined : formattedDates.endDate,
      month: creditCardId ? date?.getMonth() + 1 : undefined,
      year: creditCardId ? date?.getFullYear() : undefined,
    }
  }, [
    creditCardId,
    date,
    formattedDates.endDate,
    formattedDates.startDate,
    searchParams,
  ])

  const { data: transactions, isLoading } = useGetTransactions(
    page,
    pageSize,
    filters,
  )

  const outcomeTotalValue = useMemo(() => {
    const creditCardsTotalValue =
      creditCardsData?.reduce((acc, curr) => {
        return acc + curr.total_value
      }, 0) || 0

    return (transactions?.totals.outcome || 0) + (creditCardsTotalValue || 0)
  }, [creditCardsData, transactions?.totals.outcome])

  const accountId = useMemo(() => {
    return creditCardId
      ? transactions?.data?.find((item) => item.account?.id)?.account?.id
      : ''
  }, [creditCardId, transactions?.data])

  return (
    <div className={'w-full max-w-[1200px] m-auto flex flex-col gap-4'}>
      <div className={'w-full flex justify-between items-center'}>
        <div className={'flex flex-col'}>
          <span className={'text-lg block'}>
            {creditCardId
              ? `Fatura cartão - ${creditCardDetails?.name || ''}`
              : 'Transações'}
          </span>
        </div>

        <div className={'flex gap-2'}>
          {creditCardDetails && !!transactions?.data.length && (
            <PaymentDialog
              userAccountId={accountId || ''}
              isPaid={isPaidData?.paid || false}
              triggerElement={
                <>
                  <Tooltip
                    title={
                      isPaidData?.paid
                        ? 'Remover pagamento'
                        : 'Realizar pagamento'
                    }
                  >
                    <Button
                      size={'icon'}
                      variant={'default'}
                      type={'button'}
                      className={cn(
                        'w-12 bg-red-400 text-black h-12 rounded-full flex items-center justify-center hover:bg-red-400/70',
                        {
                          'bg-green-500 hover:bg-green-500/70':
                            !isPaidData?.paid,
                        },
                      )}
                    >
                      <DollarSign />
                    </Button>
                  </Tooltip>
                </>
              }
              creditCard={creditCardDetails}
              month={date.getMonth() + 1}
              year={date.getFullYear()}
            />
          )}

          <FiltersSidebar
            creditCardId={creditCardId || ''}
            triggerElement={
              <Button
                size={'icon'}
                variant={'default'}
                type={'button'}
                className={
                  'w-12 bg-yellow-500 text-black h-12 rounded-full flex items-center justify-center hover:bg-yellow-500/70'
                }
              >
                <Filter />
              </Button>
            }
          />

          <DropdownMenu>
            <DropdownMenuTrigger>
              <Tooltip title={'Criar transação'}>
                <Button
                  size={'icon'}
                  variant={'default'}
                  type={'button'}
                  className={
                    'w-12 h-12 rounded-full flex items-center justify-center hover:bg-primary/70'
                  }
                >
                  <Plus />
                </Button>
              </Tooltip>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>Criar transação</DropdownMenuLabel>
              <DropdownMenuSeparator />
              {!creditCardId && (
                <CreateTransactionModal
                  initialData={{
                    kind: 'income',
                    accountId: filters?.account_id || undefined,
                  }}
                  triggerElement={
                    <>
                      <TrendingUp color={'green'} className="mr-2 h-4 w-4" />
                      <span>Receita</span>
                    </>
                  }
                />
              )}

              {!creditCardId && (
                <CreateTransactionModal
                  initialData={{
                    kind: 'outcome',
                    accountId: filters?.account_id || undefined,
                  }}
                  triggerElement={
                    <>
                      <TrendingDown color={'red'} className="mr-2 h-4 w-4" />
                      <span>Despesa</span>
                    </>
                  }
                />
              )}

              <CreateTransactionModal
                initialData={{ kind: 'outcome', creditCardId }}
                showCreditCardInput={true}
                triggerElement={
                  <>
                    <CreditCard className="mr-2 h-4 w-4" />
                    <span>Despesa Cartão</span>
                  </>
                }
              />

              {!creditCardId && (
                <CreateBankTransferModal
                  triggerElement={
                    <DropdownMenuItem asChild>
                      <DropdownMenuItem asChild>
                        <>
                          <DialogTrigger
                            className={
                              'w-full relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-accent focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
                            }
                          >
                            <ArrowLeftRight
                              color={'blue'}
                              className="mr-2 h-4 w-4"
                            />
                            <span>Transferência</span>
                          </DialogTrigger>
                        </>
                      </DropdownMenuItem>
                    </DropdownMenuItem>
                  }
                />
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <div className={'w-full'}>
        {creditCardId ? (
          <MonthPicker date={date} onDateChange={changeDate} />
        ) : (
          <DatePickerWithRange date={dateRange} setDate={onChangeDateRange} />
        )}
      </div>

      <CardSection
        isLoading={isLoading}
        isCreditCard={!!creditCardId}
        values={{
          balance: (transactions?.totals.income || 0) - outcomeTotalValue,
          outcome: outcomeTotalValue,
          currentBalance: transactions?.totals.current_balance || 0,
          income: transactions?.totals.income || 0,
        }}
      />

      {creditCardId ? (
        <TransactionsTable
          isCreditCard={!!creditCardId}
          transactions={transactions}
          isLoading={isLoading}
        />
      ) : (
        <Tabs
          defaultValue={selectedTab}
          value={selectedTab}
          onValueChange={onChangeTab}
          key={'transaction-tabs'}
        >
          <TabsList>
            <TabsTrigger value="transactions">Transações</TabsTrigger>
            <TabsTrigger value="credit_cards">Cartões de crédito</TabsTrigger>
          </TabsList>
          <TabsContent value="transactions">
            <TransactionsTable
              isCreditCard={!!creditCardId}
              transactions={transactions}
              isLoading={isLoading}
            />
          </TabsContent>
          <TabsContent value="credit_cards">
            <CreditCardsTable
              isLoading={creditCardsDataIsLoading}
              items={creditCardsData || []}
            />
          </TabsContent>
        </Tabs>
      )}
    </div>
  )
}
