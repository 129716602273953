import { CreditCardsList } from '@/pages/credit_cards/components/list.tsx'
import { CreateCreditCardModal } from '@/pages/credit_cards/components/create-credit-card-modal.tsx'
import { Plus } from 'lucide-react'
import { Button } from '@/components/ui/button.tsx'

export function CreditCards() {
  return (
    <div className={'w-full max-w-[1200px] m-auto'}>
      <div className={'w-full mb-4 flex justify-between items-center'}>
        <span className={'text-lg block'}>Cartões de crédito</span>
        <CreateCreditCardModal
          triggerElement={
            <>
              <Button
                size={'icon'}
                variant={'default'}
                className={
                  'w-12 h-12 rounded-full flex items-center justify-center hover:bg-primary/70'
                }
              >
                <Plus />
              </Button>
            </>
          }
        />
      </div>
      <CreditCardsList />
    </div>
  )
}
