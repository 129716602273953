import {
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  CategoryFormModal,
  ICategoryFormType,
} from '@/pages/categories/components/category-form-modal.tsx'
import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  createCategory,
  CreateCategoryInput,
} from '@/lib/react-query/requests/categories/create-category.ts'
import { toast } from 'sonner'
import { GET_CATEGORIES } from '@/lib/react-query/keys.ts'

const CLOSE_DIALOG_ELEMENT_ID = 'create-category-dialog-id'

export const CreateCategoryModal = () => {
  const queryClient = useQueryClient()

  const closeModal = useCallback(() => {
    const el = document.getElementById(CLOSE_DIALOG_ELEMENT_ID)
    if (el) el.click()
  }, [])

  const { mutate: handleCreateCategory, isPending } = useMutation({
    mutationFn: (data: CreateCategoryInput) => createCategory(data),
    onSuccess: async () => {
      toast.success('Categoria criada com sucesso')
      await queryClient.invalidateQueries({
        queryKey: [GET_CATEGORIES],
      })
      closeModal()
    },
  })

  const onSubmit = useCallback(
    (data: ICategoryFormType) => {
      handleCreateCategory(data)
    },
    [handleCreateCategory],
  )

  return (
    <DialogContent closeButtonElementId={CLOSE_DIALOG_ELEMENT_ID}>
      <DialogHeader>
        <DialogTitle>Nova Categoria</DialogTitle>
      </DialogHeader>
      <CategoryFormModal
        isLoading={isPending}
        initialData={null}
        onSubmit={onSubmit}
      />
    </DialogContent>
  )
}
