import { api } from '@/lib/api.ts'

type UploadFileResponse = {
  url: string
}

export const uploadFile = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  const { data } = await api.post<UploadFileResponse>(
    '/uploads/upload',
    formData,
  )
  return data.url
}
