import { useQuery } from '@tanstack/react-query'
import { api } from '@/lib/api.ts'
import { GET_CHECK_CREDIT_CARD_PAYMENT } from '@/lib/react-query/keys.ts'

interface CheckCreditCardPaymentOutput {
  paid: boolean
}

export const useCheckCreditCardPayment = (
  id: string,
  month: number,
  year: number,
) => {
  return useQuery({
    queryKey: [GET_CHECK_CREDIT_CARD_PAYMENT, id, month, year],
    enabled: !!id && !!month && !!year,
    queryFn: async () => {
      const { data } = await api.get<CheckCreditCardPaymentOutput>(
        `/credit_cards/${id}/month/${month}/year/${year}/check_payment`,
      )
      return data
    },
  })
}
