import { ChangeEvent } from 'react'
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { cn } from '@/lib/utils.ts'

interface Option {
  label: string
  value: any
}

interface SelectFieldProps<T extends FieldValues> {
  name: Path<T>
  label: string
  options: Option[]
  disabled?: boolean
  placeholder?: string
  onChange?: (() => void) | null
  formClassName?: string
}

export function SelectField<T extends FieldValues>({
  name,
  label,
  options,
  placeholder,
  disabled = false,
  onChange = null,
  formClassName,
}: SelectFieldProps<T>) {
  const { control } = useFormContext<T>()
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <FormItem className={cn('w-full', formClassName)}>
            <FormLabel>{label}</FormLabel>
            <Select
              onValueChange={(value) => {
                field.onChange(
                  value as ChangeEvent<Element> | PathValue<T, Path<T>>,
                )
                if (typeof onChange === 'function') {
                  onChange()
                }
              }}
              defaultValue={field.value}
              value={field.value ?? ''}
            >
              <FormControl>
                <SelectTrigger disabled={disabled}>
                  <SelectValue placeholder={placeholder} />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {options.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}
