import {
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { ButtonLoading } from '@/components/form/button-loading.tsx'
import { useCallback } from 'react'
import { deleteCreditCard } from '@/lib/react-query/requests/credit_cards/delete-credit-card.ts'
import { CreditCardsOutput } from '@/lib/react-query/requests/credit_cards/get-credit-cards.ts'
import { GET_CREDIT_CARDS } from '@/lib/react-query/keys.ts'

interface DialogRegisterDialogProps {
  creditCard: CreditCardsOutput['data'][0]
}

const CLOSE_BUTTON_ID = 'remove-credit-card-close-action'

export function DeleteRegisterDialog({
  creditCard,
}: DialogRegisterDialogProps) {
  const queryClient = useQueryClient()

  const { mutate: deleteRegister, isPending } = useMutation({
    mutationFn: () => deleteCreditCard(creditCard.id),
    onSuccess: async () => {
      toast.success('Cartão de crédito removido com sucesso')
      await queryClient.invalidateQueries({
        queryKey: [GET_CREDIT_CARDS],
      })
      close()
    },
  })

  const close = useCallback(() => {
    const el = document.getElementById(CLOSE_BUTTON_ID)
    if (el) el.click()
  }, [])

  return (
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>Tem certeza ?</AlertDialogTitle>
        <AlertDialogDescription>
          Cartão de crédito: <strong>{creditCard.name}</strong>
        </AlertDialogDescription>
        <AlertDialogDescription>
          Essa é uma ação irreversível. Deseja continuar ?
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel id={CLOSE_BUTTON_ID}>Cancelar</AlertDialogCancel>
        <ButtonLoading
          className={cn(buttonVariants({ variant: 'destructive' }))}
          isLoading={isPending}
          onClick={() => deleteRegister()}
        >
          Confirmar
        </ButtonLoading>
      </AlertDialogFooter>
    </AlertDialogContent>
  )
}
