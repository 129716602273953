import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/ui/select'
import { CalendarDaysIcon } from 'lucide-react'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import { useMemo, useState } from 'react'

interface MonthPickerProps {
  date: Date
  onDateChange: (date: Date) => void
}

export default function MonthPicker({ date, onDateChange }: MonthPickerProps) {
  const [open, setOpen] = useState(false)

  const [month, setMonth] = useState(date.getMonth())
  const [year, setYear] = useState(date.getFullYear())

  const onMonthChange = (month: string) => {
    setMonth(Number(month))
  }

  const onYearChange = (year: string) => {
    setYear(Number(year))
  }

  const months = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) => i)
  }, [])

  const years = useMemo(() => {
    return Array.from(
      { length: 10 },
      (_, i) => new Date().getFullYear() - 5 + i,
    )
  }, [])

  const onApply = (year: number, month: number) => {
    setYear(year)
    setMonth(month)
    const newDate = new Date(year, month, 1)
    onDateChange(newDate)
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className="flex items-center justify-between w-full max-w-[200px] px-4 py-2 rounded-md bg-card text-card-foreground hover:bg-muted hover:text-muted-foreground focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background"
        >
          <div className="flex items-center gap-2">
            <CalendarDaysIcon className="w-5 h-5" />
            <span className="text-sm font-medium">
              {date.toLocaleString('pt-BR', {
                month: 'long',
                year: 'numeric',
              })}
            </span>
          </div>
          <ChevronDownIcon className="ml-2 w-4 h-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-4 space-y-4 bg-card text-card-foreground rounded-md shadow-lg w-auto">
        <div className="flex items-center justify-between gap-2">
          <Select value={String(month)} onValueChange={onMonthChange}>
            <SelectTrigger className="px-3 py-2 rounded-md bg-muted text-muted-foreground hover:bg-muted-foreground hover:text-muted">
              <SelectValue placeholder="Selecione o mês" />
            </SelectTrigger>
            <SelectContent>
              {months.map((month) => (
                <SelectItem key={month} value={String(month)}>
                  {new Date(0, month).toLocaleString('pt-BR', {
                    month: 'long',
                  })}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select value={String(year)} onValueChange={onYearChange}>
            <SelectTrigger className="px-3 py-2 rounded-md bg-muted text-muted-foreground hover:bg-muted-foreground hover:text-muted">
              <SelectValue placeholder="Selecione o ano" />
            </SelectTrigger>
            <SelectContent>
              {years.map((year) => (
                <SelectItem key={year} value={String(year)}>
                  {year}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex justify-end gap-2">
          <Button
            variant="secondary"
            onClick={() => {
              onApply(new Date().getFullYear(), new Date().getMonth())
            }}
          >
            Hoje
          </Button>
          <Button
            variant="default"
            onClick={() => {
              onApply(year, month)
            }}
          >
            Aplicar
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
