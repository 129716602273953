import { useQuery } from '@tanstack/react-query'
import { api } from '@/lib/api.ts'
import { GET_VALUES_BY_KIND_BY_CATEGORY } from '@/lib/react-query/keys.ts'

export interface GetValuesByCategoriesByKind {
  id: string
  name: string
  color: string
  total: number
}

export const useGetValuesByKindByCategory = (
  kind: 'income' | 'outcome',
  start_date: string,
  end_date: string,
) => {
  return useQuery({
    queryKey: [GET_VALUES_BY_KIND_BY_CATEGORY, kind, start_date, end_date],
    enabled: !!kind && !!start_date && !!end_date,
    queryFn: async () => {
      const { data } = await api.get<GetValuesByCategoriesByKind[]>(
        `/dashboard/${kind}/categories`,
        {
          params: {
            start_date,
            end_date,
          },
        },
      )
      return data
    },
  })
}
