import { getCurrentJwt } from '@/lib/get-current-jwt'
import axios from 'axios'

const api = axios.create({
  baseURL: `/api/v1`,
})

api.interceptors.request.use(
  function (config) {
    const token = getCurrentJwt()
    if (!token) throw new axios.Cancel('Canceled because token is not set')
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

export { api }
