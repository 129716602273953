import { TooltipProps as RadixTooltipProps } from '@radix-ui/react-tooltip'
import { SIDE_OPTIONS } from '@radix-ui/react-popper'
import {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Tooltip as ShadTooltip,
} from './ui/tooltip'
import { ReactNode } from 'react'

export interface TooltipProps extends RadixTooltipProps {
  children: ReactNode
  title: string
  side?: (typeof SIDE_OPTIONS)[number]
}

export function Tooltip({
  title,
  children,
  delayDuration = 100,
  disableHoverableContent,
  side = 'top',
}: TooltipProps) {
  return (
    <TooltipProvider
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
    >
      <ShadTooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent side={side}>{title}</TooltipContent>
      </ShadTooltip>
    </TooltipProvider>
  )
}
