import { api } from '@/lib/api.ts'

export interface UndoPayCreditCardInput {
  month: number
  year: number
}

export const undoPayCreditCard = async (
  id: string,
  data: UndoPayCreditCardInput,
) => {
  await api.put(`/credit_cards/${id}/undo_pay`, data)
}
