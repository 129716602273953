import { useQuery } from '@tanstack/react-query'
import { api } from '@/lib/api.ts'
import { GET_CREDIT_CARD_DETAILS } from '@/lib/react-query/keys.ts'

interface CreditCardAccount {
  id: string
  name: string
}

interface CreditCardDetailsOutput {
  id: string
  name: string
  limit: number
  color: string
  due_day: number
  closing_day: number
  current_amount: number
  account: CreditCardAccount
  invoices: {
    current: {
      total: number
      period: string
    }
    previous: {
      total: number
      period: string
    }
  }
}

export const useGetCreditCardDetails = (id?: string) => {
  return useQuery({
    queryKey: [GET_CREDIT_CARD_DETAILS, id],
    enabled: !!id,
    queryFn: async () => {
      const { data } = await api.get<CreditCardDetailsOutput>(
        `/credit_cards/${id}`,
      )
      return data
    },
  })
}
